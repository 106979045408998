import React, { useContext, useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from "react-router-dom"
import InputPassword from '../../components/utils/InputPassword'
import { Context } from "../../index"
import InputMask from 'react-input-mask'
import { LOGIN_ROUTE, PROFILE_ROUTE } from '../../utils/consts'
import { newPassword } from './../../http/userAPI';

const RestorePassword = () => {
      const { user } = useContext(Context)
      const navigate = useNavigate()
      const [phone, setPhone] = useState('')
      const [key, setKey] = useState('')
      const [password, setPassword] = useState('')
      const [confirmPassword, setConfirmPassword] = useState('')
      const [step, setStep] = useState(1);

      useEffect(() => {
            if (user.isAuth) {
                  navigate(PROFILE_ROUTE)
            }
      }, []);

      const submit = async (e) => {
            try {
                  e.preventDefault()
                  if (step == 1) {
                        if (!phone || phone.length < 16) {
                              NotificationManager.error('Введите номер телефона')
                              return;
                        }
                        let data = await newPassword({ phone, step })

                        if (data && data.status) {
                              setStep(2)
                        } else {
                              NotificationManager.error('Неверно введен номер телефона')
                        }
                  } else if (step == 2) {
                        if (!key || key.length < 7) {
                              NotificationManager.error('Введите код подтверждения')
                              return;
                        }
                        let data = await newPassword({ phone, key, step })

                        if (data && data.status) {
                              setStep(3)
                        } else {
                              NotificationManager.error('Неверно введен код подтверждения')
                        }
                  } else if (step == 3) {
                        if (!password || password.length < 4) {
                              NotificationManager.error('Пароль должен состоять не менее, чем из 4 символов')
                              return;
                        }
                        if (!confirmPassword || confirmPassword != password) {
                              NotificationManager.error('Пароли не совпадают')
                              return;
                        }
                        let data = await newPassword({ phone, password, confirmPassword, step })

                        if (data && data.status) {
                              step(4)
                        } else {
                              NotificationManager.error('Ошибка при смене пароля, повторите попытку позже')
                        }
                  }
            } catch (e) {
                  NotificationManager.error(e.response.data.message)
            }
      }
      return (
            <main>
                  <Container>
                        <section id="enter-page">
                              <h1 className='inner text-center'>Восстановление пароля</h1>

                              <Row className='justify-content-center'>
                                    <Col md={9} lg={7} xl={6}>
                                          {
                                                step === 1 &&
                                                <form onSubmit={submit}>
                                                      <h5 className='fw-5 text-center mb-4 mb-sm-5'>Код подтверждения будет отправлен Вам на указанный номер телефона</h5>
                                                      <label htmlFor='phone' className='fs-13 fw-7 mb-2'>Номер телефона</label>
                                                      <InputMask mask="+7 999 999 99 99" placeholder="+7 000 000 00 00" name="phone" maskChar="" defaultValue={phone} value={phone} onChange={(e) => setPhone(e.target.value)} className='fs-11 fw-5 mb-4' />
                                                      <Row className='justify-content-center'>
                                                            <Col md={6}>
                                                                  <button type='submit' disabled={phone.length < 16} className='btn-1 fs-13 w-100'><span>Получить код</span></button>
                                                            </Col>
                                                      </Row>
                                                </form>
                                          }
                                          {
                                                step === 2 &&
                                                <form onSubmit={submit}>
                                                      <h5 className='fw-5 text-center mb-4 mb-sm-5'>Код подтверждения был отправлен Вам на указанный номер телефона</h5>
                                                      <div className='fs-13 fw-4 mb-2 text-center'>Введите код подтверждения</div>
                                                      <InputMask mask="9 9 9 9" placeholder="0 0 0 0" name="key" maskChar="" defaultValue={key} value={key} onChange={(e) => setKey(e.target.value)} className='text-center fs-17 fw-7 mb-4 input-key m-auto' />
                                                      <Row className='gx-3 gx-lg-4'>
                                                            <Col md={5}>
                                                                  <button type='submit' disabled={key.length < 7} className='btn-1 fs-13 w-100'><span>Отправить</span></button>
                                                            </Col>
                                                            <Col md={7}>
                                                                  <button type='button' disabled className='btn-3 fs-13 w-100 mt-2 mt-sm-3 mt-md-0'>Получить код ещё раз</button>
                                                            </Col>
                                                      </Row>
                                                </form>
                                          }
                                          {
                                                step === 3 &&
                                                <form onSubmit={submit}>
                                                      <div className='fs-13 fw-7 mb-2'>Пароль</div>
                                                      <InputPassword className="fs-11 fw-5 mb-4" defaultValue={password} value={password} onChange={(e) => setPassword(e.target.value)} />

                                                      <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <div className='fs-13 fw-7'>Повторите пароль</ div>
                                                            {(password && confirmPassword === password) ? <div className='success-color fs-09'>Пароли совпадают</div> : password && confirmPassword && <div className='error-color fs-09'>Пароли не совпадают</div>}
                                                      </div>
                                                      <InputPassword className="fs-11 fw-5 mb-4" defaultValue={confirmPassword} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />

                                                      <Row className='justify-content-center'>
                                                            <Col md={6}>
                                                                  <button type='submit' disabled={password.length < 4 || password != confirmPassword} className='btn-1 fs-13 w-100'><span>Сохранить</span></button>
                                                            </Col>
                                                      </Row>
                                                </form>
                                          }
                                    </Col>
                              </Row>
                        </section>
                  </Container>
            </main>
      );
}

export default RestorePassword
