import { $host } from "./index";

export const getProducts = async (categoryId = false, page = 1, limit = 40, filter) => {
    const { data } = await $host.post('/product/getProducts', { categoryId, page, limit, filter })
    console.log(data)
    return data
}
export const getSearch = async (text, page = 1) => {
    const { data } = await $host.post('/product/getSearch', { text, page })
    return data
}
export const getRecommeds = async () => {
    const { data } = await $host.post('/product/getRecommend')
    return data
}
export const getProduct = async (id) => {
    const { data } = await $host.post('/product/getProduct', { id })
    return data
}
export const createReview = async (array) => {
    const { data } = await $host.post('/product/createReview', array)
    return data
}