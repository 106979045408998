import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AccountMenu from '../pages/profile/AccountMenu';
import Home from '../pages/profile/Home';
import Orders from '../pages/profile/Orders';
import Edit from '../pages/profile/Edit';
import Addresses from '../pages/profile/Addresses';
import Points from '../pages/profile/Points';
import Companies from '../pages/profile/Companies';
import PersonalAccountLayout from '../pages/profile/PersonalAccountLayout';
import Order from '../pages/profile/Order';

const PersonalAccountRouter = ({ isMobile }) => {
    return (
        <Routes>
            <Route path="/" element={<PersonalAccountLayout isMobile={isMobile} />}>
                {isMobile
                    ? <Route index element={<AccountMenu />} />
                    : <Route index element={<Navigate to="profile" replace={true} />} />
                }
                <Route path="profile" element={<Home />} />
                <Route path="edit" element={<Edit />} />
                <Route path="companies" element={<Companies />} >
                    <Route path=":action" element={<Companies />} />
                    <Route path=":action/:companyId" element={<Companies />} />
                </Route>
                <Route path="orders" element={<Orders />} >
                    <Route path=":page" element={<Orders />} />
                </Route>

                <Route path="addresses" element={<Addresses />} >
                    <Route path=":action" element={<Addresses />} />
                    <Route path=":action/:addressId" element={<Addresses />} />
                </Route>
                <Route path="points" element={<Points />} />
            </Route>
            <Route path="order" element={<Order />} >
                <Route path=":orderId" element={<Order />} />
            </Route>
        </Routes>
    );
};

export default PersonalAccountRouter;