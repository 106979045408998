import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { IoSettingsOutline } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { getAddresses, getCompanies } from "../../http/userAPI";
import { Context } from "../../index";
import { PROFILE_ROUTE } from '../../utils/consts';

const Home = () => {
    const { user } = useContext(Context)
    const [companies, setCompanies] = useState(false)
    const [addresses, setAddresses] = useState(false)

    useEffect(() => {
        document.title = 'Профиль'
        getCompanies(true).then(e => (e && e.company) && setCompanies(e.company))
        getAddresses(true).then(e => (e && e.address) && setAddresses(e.address))
    }, [])

    return (
        <Row>
            <Col xl={7}>
                <div className='box-2 p-lg-4'>
                    <form>
                        <h1 className="d-none mb-4 d-md-flex justify-content-between">Личный кабинет <Link to={PROFILE_ROUTE + '/edit'}><IoSettingsOutline size={25} /></Link></h1>
                        <p className='gray fs-09 mb-2'>{user.user.type == 1 ? 'Физическое лицо' : 'Юридическое лицо'}</p>
                        <h5 className="gray-1">{user.user.firstName ?? 'Имя'} {user.user.lastName ?? 'Фамилия'} {user.user.patronymic}</h5>
                        <Row lg={2}>
                            {user.user.phone &&
                                <Col>
                                    <fieldset className="mb-3 mb-sm-4">
                                        <legend className="gray-1 fw-5 fs-11 mb-2">Телефон</legend>
                                        <div className="d-sm-flex">
                                            {user.user.phone}
                                        </div>
                                    </fieldset>
                                </Col>
                            }
                            {user.user.email &&
                                <Col>
                                    <fieldset className="mb-3 mb-sm-4">
                                        <legend className="gray-1 fw-5 fs-11 mb-2">Email</legend>
                                        <div className="d-sm-flex">
                                            {user.user.email}
                                        </div>
                                    </fieldset>
                                </Col>
                            }
                        </Row>
                        <Row lg={2}>
                            {user.user.birthday &&
                                <Col>
                                    <fieldset className="">
                                        <legend className="gray-1 fw-5 fs-11 mb-2">День рождения</legend>
                                        <div className="d-sm-flex">
                                            {moment(user.user.birthday).format('LL')}
                                        </div>
                                    </fieldset>
                                </Col>
                            }
                            {user.user.sex > 0 &&
                                <Col>
                                    <fieldset className="">
                                        <legend className="gray-1 fw-5 fs-11 mb-2">Пол</legend>
                                        <div className="d-sm-flex">
                                            {user.user.sex == 1 ? 'Мужской' : 'Женский'}
                                        </div>
                                    </fieldset>
                                </Col>
                            }
                        </Row>
                        {(user.user.type == 2 && companies) && <>
                            <hr />
                            <fieldset className="mb-3 mb-sm-4">
                                <legend className="gray-1 fw-5 fs-11 mb-2">Компания по умолчанию</legend>
                                <div className="d-sm-flex">
                                    {companies.name}
                                </div>
                            </fieldset>
                            <Row lg={2}>
                                <Col>
                                    <fieldset>
                                        <legend className="gray-1 fw-5 fs-11 mb-2">ИНН</legend>
                                        <div className="d-sm-flex">
                                            {companies.inn}
                                        </div>
                                    </fieldset>
                                </Col>
                                <Col>
                                    <fieldset>
                                        <legend className="gray-1 fw-5 fs-11 mb-2">ОГРАН(ИП)</legend>
                                        <div className="d-sm-flex">
                                            {companies.ogrn}
                                        </div>
                                    </fieldset>
                                </Col>
                            </Row>
                        </>
                        }
                        {(user.user.type == 2 && addresses) && <>
                            <hr />
                            <fieldset>
                                <legend className="gray-1 fw-5 fs-11 mb-2">Адрес по умолчанию</legend>
                                <div className="d-sm-flex">
                                    {addresses.title}
                                </div>
                                <div className='gray'>{addresses.street} {addresses.home}</div>
                            </fieldset>
                        </>
                        }
                    </form>
                </div>
            </Col>
            <Col className='d-none d-xl-block' xl={5}>
                <aside className='mb-4'></aside>
                <aside className='mb-4'></aside>
            </Col>
        </Row>
    )
};

export default Home;
