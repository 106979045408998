import React, { useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getArticles } from '../http/articleAPI';
import ArticlePreview from './ArticlePreview';

export default function ArticlesSlider(props) {
    const [data, setData] = useState()

    useEffect(() => {
        getArticles(0, 1, 10)
            .then(data => {
                if (data && data.status) {
                    setData(data.articles)
                }
            });
    }, [])

    if (!data) {
        return null
    }

    return (
        <div className='position-relative'>
            <Swiper
                className='position-unset'
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={1}
                navigation
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                }}
            >
                {
                    data.map(item => <SwiperSlide><ArticlePreview data={item} /></SwiperSlide>)
                }
            </Swiper>
        </div>
    );
}