import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FiSearch } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import CategoryCard from '../components/CategoryCard';
import Error from '../components/Error';
import Loading from '../components/loading/Loading';
import NavPagination from '../components/NavPagination';
import ProductCard from '../components/ProductCard';
import useDebounce from "../hooks/useDebounce";
import { getCategories } from "../http/categoryAPI";
import { getSearch } from "../http/productAPI";
import { SEARCH_ROUTE } from './../utils/consts';

export default function Search() {
      const { text, page } = useParams()
      const [categories, setCategories] = useState(false)
      const [stateText, setStateText] = useState(text ?? '')
      const [search, setSearch] = useState([])
      const searchText = useDebounce(stateText)
      const [error, setError] = useState(false)
      const [loading, setLoading] = useState(true)

      useEffect(() => {
            document.title = "Поиск"
            getCategories().then(data => {
                  if (data && data.status) {
                        setCategories(data.categories)
                  }
            })
                  .catch(() => setError(true))
                  .finally(() => setLoading(false));
      }, [])

      const getData = (newPage = false) => {
            getSearch(stateText, newPage ?? page).then(data => {
                  if (data && data.status) {
                        setSearch(data)
                  }
            })
      }
      useEffect(() => {
            if (searchText) {
                  getData(1)
            }
      }, [searchText])

      useEffect(() => {
            setLoading(true)
            getData()
      }, [page])

      if (error) {
            return <Error />
      }

      if (loading) {
            return <Loading full/>
      }

      return (
            <main>
                  <Container className='mb-6'>
                        <h1 className='inner mb-4 mb-md-5'>Поиск</h1>

                        <Row className='mb-4 mb-md-5'>
                              <Col md={8} lg={6}>
                                    <form className='form-search'>
                                          <input type='search' placeholder='Поиск' value={stateText} defaultValue={stateText} onChange={e => setStateText(e.target.value)} />
                                          <button type='submit'><FiSearch /></button>
                                    </form>
                              </Col>
                        </Row>
                        {
                              search && search.products && search.products.length > 0 ? <>
                                    <Row xs={2} md={3} lg={5} className="gy-4 gx-3 g-sm-5 g-md-4 g-xl-5">
                                          {search.products.map(item => <Col><ProductCard data={item} /></Col>)}
                                    </Row>
                                    <NavPagination pagination={search.pagination} link={SEARCH_ROUTE + '/' + stateText} />
                              </>
                                    : <p>Ничего не найдено</p>
                        }
                        <div className='mt-5'>
                              <Row xs={2} md={3} lg={4} className="g-2 g-sm-3 g-xl-4">
                                    {
                                          categories && categories.map(item => <Col>
                                                <CategoryCard item={item} />
                                          </Col>)
                                    }
                              </Row>
                        </div>
                  </Container>
            </main>
      );
}