import { IoChevronForward } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { PROFILE_ROUTE } from '../utils/consts';

export default function AddressItem(props) {
    const { item } = props
    return (
        <div className='address'>
            <label>
                <input type='radio' name="address" checked={item.index === 0 && !item.main || item.main} />
                <span className='fs-13 fw-7 ms-2 ms-sm-4'>{item.title ?? item.street}</span>
            </label>
            <Link to={PROFILE_ROUTE + '/addresses/edit/' + item.id} className='btn-1 fs-11'>
                <IoChevronForward className='white fs-15 d-md-none' />
                <span className='d-none d-md-block'>Редактировать</span>
            </Link>
        </div>
    );
}