import { Link } from 'react-router-dom';
import { FiRefreshCcw } from "react-icons/fi";
import moment from 'moment';
import { customPrice } from '../hooks/useGlobalHook';
import { PROFILE_ROUTE } from '../utils/consts';

export default function OrderItem(props) {
    const { item } = props
    if (!item) {
        return null
    }
    const products = item.products && typeof (item.products) === 'string' ? JSON.parse(item.products) : item.products

    return (
        <div className='order'>
            <div className='d-flex justify-content-between align-items-center'>
                <h5 className='mb-0'><Link to={PROFILE_ROUTE + '/order/' + item.id}>Номер заказа {item.id}</Link></h5>
                {
                    item.status === 1 && <button type='button' className='btn-1 py-2 px-2 px-sm-3'>
                        <FiRefreshCcw />
                        <span className='ms-1'>Повторить заказ</span>
                    </button>
                }
            </div>
            <hr />
            <div className='info'>
                <div className='gray'>{products.length} товара • {customPrice(item.total)} ₽ • {moment(item.createdAt).fromNow()}</div>
                <div className='text-end mt-2 mt-md-0'>Статус заказа: <span className={item.status === 2 ? 'danger-color' : 'gray'}>
                    {
                        item.status === 0
                            ? 'Не оплачен'
                            : item.status === 2 ?
                                'Отменен'
                                : 'Оплачен'
                    }
                </span></div>
            </div>
        </div>
    );
}