import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FiRefreshCcw } from "react-icons/fi"
import { VscArrowLeft, VscChevronDown, VscChevronUp } from "react-icons/vsc"
import { NotificationManager } from 'react-notifications'
import { Link, useParams } from "react-router-dom"
import CustomModal from '../../components/CustomModal'
import Loading from "../../components/loading/Loading"
import OrderProductItem from '../../components/OrderProductItem'
import useIsMobile from '../../hooks/isMobile'
import { customPrice, paymentText, statusText } from "../../hooks/useGlobalHook"
import { getOrder } from "../../http/orderAPI"
import { Context } from "../../index"
import { PROFILE_ROUTE } from '../../utils/consts'

const Order = () => {
    const { cart } = useContext(Context)
    const { orderId } = useParams()
    const [order, setOrder] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [full, setFull] = useState(true);
    const { mobile } = useIsMobile();

    useEffect(() => {
        if (mobile === true) {
            setFull(false);
        } else { setFull(true); }
    }, [mobile]);

    useEffect(() => {
        if (orderId) {
            getOrder(orderId).then(data => {
                if (data && data.status) {
                    setOrder(data.order)
                }
            }).finally(() => setLoading(false))
        }
    }, [orderId])

    const setCartOrder = (yes) => {
        if (cart.products.length > 0) {
            if (!yes || yes != 'add') {
                setOpenModal(!openModal)
                return
            }
        }
        cart.setIdsCart(order.products)
        NotificationManager.success('Товары добавлены в корзину')
        setOpenModal(false)
    }

    if (loading) {
        return <Loading full/>
    }

    return <>
        <div className='order-page'>
            <div className='d-flex align-items-center d-md-block mb-2 mb-sm-4 mb-md-4'>
                <Link to={PROFILE_ROUTE + '/orders'} className="btn-4 fs-11 px-2 px-sm-3 mb-md-5">
                    <VscArrowLeft className='fs-12' />
                    <span className='ms-2 d-none d-md-inline'>Назад в историю покупок</span>
                </Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4 ms-md-0'>Заказ {order.id}</h1>
            </div>
            <Row className='justify-content-between'>
                <Col lg={8}>
                    <Row className="mb-4">
                        <Col lg={3}>
                            <h5 className='mb-2'>Статус заказа</h5>
                            <p className={order.status == 1 || order.status == 4 ? 'success-color' : order.status == 1 || order.status == 2 ? 'danger-color' : 'gray'}>{statusText(order.status)}</p>
                        </Col>
                        <Col lg={5}>
                            <h5 className='mb-2'>{order.delivery == 1 ? 'Самовывоз' : 'Доставка'}</h5>
                            {
                                order.delivery == 1 ?
                                    <p>
                                        <span className='gray'>ул. Комиссара Габишева</span>
                                    </p>
                                    :
                                    <p>
                                        <span>Время доставки: </span>
                                        <span className='gray'>5 часов, 46 минут</span>
                                    </p>
                            }
                        </Col>
                        <Col lg={3}>
                            <h5 className='mb-2'>Способ оплаты</h5>
                            <p>
                                <span className='gray'>{paymentText(order.paymentType)}</span>
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    {order.products && order.products.map(item => <OrderProductItem item={item} />)}
                </Col>
                <Col lg={4} xxl={3} className='position-relative'>
                    <div className='total'>
                        <div className="d-block">
                            <h5 onClick={() => setFull((full === true) ? false : true)} className='order-1 d-flex align-items-center mb-2 mb-md-3 mb-lg-4'>
                                <span className='me-4'>Ваш заказ</span>
                                {
                                    (full === true)
                                        ? <VscChevronUp className='d-lg-none fs-12' />
                                        : <VscChevronDown className='d-lg-none fs-12' />
                                }
                            </h5>
                        </div>
                        <div className='order-1 order-lg-4 fw-6 fs-13 d-flex align-items-center justify-content-between py-lg-2'>
                            <div>Итого</div>
                            <div>{customPrice(order.total)} ₽</div>
                        </div>
                        <button type="button" onClick={setCartOrder} className='order-4 btn-1 fs-11 w-100 px-4 mt-2'><FiRefreshCcw /><span className='ms-2'>Повторить заказ</span></button>
                    </div>
                </Col>
            </Row>
        </div>
        <CustomModal isShow={openModal} setIsShow={setOpenModal}>
            <div className='text-center'>
                <h5 className='text-center fs-15 mb-2'>В корзине уже есть товары</h5>
                <p className='fs-12 gray mb-4'>Заменить их на товары из данного заказа?</p>
                <div className='d-flex flex-row'>
                    <button className='order-4 btn-1 fs-11 w-100 px-4 me-3 mt-2' type="button" onClick={() => setCartOrder('add')}><span>Да, продолжить</span></button>
                    <button className='order-4 btn-3 fs-11 w-100 px-4 mt-2' type="button" onClick={() => setOpenModal(!openModal)}><span>Отмена</span></button>
                </div>
            </div>
        </CustomModal>
    </>
}

export default Order
