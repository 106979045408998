import { observer } from "mobx-react-lite"
import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom"
// import CartContent from "../components/Cart"
// import RecommendList from "../components/RecommendList"
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { FaRegTrashAlt } from "react-icons/fa"
import { VscArrowLeft, VscChevronDown, VscChevronUp } from "react-icons/vsc"
import CartItem from '../components/CartItem'
import Sort from '../components/Sort'
import { customPrice } from "../hooks/useGlobalHook"
import { Context } from "../index"
import { CHECKOUT_ROUTE } from "../utils/consts"
// import NavPagination from '../components/NavPagination'
import CustomModal from '../components/CustomModal'

import useIsMobile from '../hooks/isMobile'
import { filterData } from './../hooks/useGlobalHook';

const Cart = observer(() => {
    const { cart } = useContext(Context)
    const [recommend, setRecommend] = useState(false)
    const [full, setFull] = useState(true);
    const { mobile } = useIsMobile();
    const [openModal, setOpenModal] = useState(false)
    const [openSale, setOpenSale] = useState(false)
    const [openPoint, setOpenPoint] = useState(false)
    const [filter, setFilter] = useState(filterData)

    useEffect(() => {
        if (mobile === true) {
            setFull(false);
        } else { setFull(true); }
    }, [mobile]);

    useEffect(() => {
        document.title = "Корзина"
        // getRecommeds().then(data => {
        //     if (data) {
        //         setRecommend(data.recommend)
        //     }
        // }).catch(e => console.log(e))
    }, [])

    useEffect(() => {
        if (filter) {
            cart.sortProducts(filter.sort, cart.products)
            localStorage.setItem('filter', JSON.stringify(filter))
        }
    }, [filter]);

    const removeAllProducts = () => {
        cart.removeAllCart()
        setOpenModal(false)
    }
    return (
        <main className='total-padding'>
            <Container>
                <section className='mb-6'>
                    {
                        cart.products.length > 0 ?
                            <>
                                <div className='d-flex align-items-center d-md-block mb-3 mb-sm-4 mb-md-0'>
                                    <Link to="/" className="btn-4 fs-11 px-3 mb-md-5">
                                        <VscArrowLeft className='fs-12' />
                                        <span className='ms-2 d-none d-md-inline'>Назад на главную</span>
                                    </Link>
                                    {
                                        (cart.products.length > 0)
                                            ? <h1 className='inner mb-0 mb-md-5 ms-2 ms-sm-4 ms-md-0'>В корзине {cart.products.length} товар(-ов)</h1>
                                            : <h1 className='inner mb-0 mb-md-5 ms-2 ms-sm-4 ms-md-0'>Корзина</h1>
                                    }
                                </div>
                                <Row className='justify-content-between'>
                                    <Col lg={8}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div>
                                                <button type='button' onClick={() => setOpenModal(!openModal)} className='d-flex align-items-center'><FaRegTrashAlt size={18} /><span className='ms-2 d-md-inline'>Очистить корзину</span></button>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                <Sort value={filter.sort} onChange={(e) => setFilter({ ...filter, sort: e })} />
                                            </div>
                                        </div>
                                        <hr />
                                        {
                                            cart.products && cart.products.map((item, i) => <CartItem item={item} />)
                                        }
                                    </Col>
                                    <Col lg={4} xxl={3} className='position-relative'>
                                        <div className='total'>
                                            <div className="d-block">
                                                <h5 onClick={() => setFull((full === true) ? false : true)} className='order-1 d-flex align-items-center mb-2 mb-md-3 mb-lg-4'>
                                                    <span className='me-4'>Корзина</span>
                                                    {
                                                        (full === true)
                                                            ? <VscChevronUp className='d-lg-none fs-12' />
                                                            : <VscChevronDown className='d-lg-none fs-12' />
                                                    }
                                                </h5>
                                            </div>
                                            {
                                                (mobile === false || full === true) &&
                                                <div className="d-block">
                                                    <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-row justify-content-between pb-3'>
                                                            <div>Товары</div>
                                                            <div>{customPrice(cart.totalNoSale)} ₽</div>
                                                        </div>
                                                        {
                                                            cart.totalSale > 0 && <>
                                                                <div className='cursor-pointer d-flex flex-row justify-content-between pb-3' onClick={() => setOpenSale(!openSale)} aria-expanded={openSale}>
                                                                    <div>Скидка</div>
                                                                    <div className="d-flex align-items-center pe-3 pe-sm-0">{customPrice(cart.totalSale)} ₽
                                                                        {
                                                                            openSale ?
                                                                                <VscChevronUp color="#999" className='point-icon fs-12' />
                                                                                :
                                                                                <VscChevronDown color="#999" className='point-icon fs-12' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Collapse in={openSale}>
                                                                    <div>
                                                                        {
                                                                            cart.products && cart.products.map(e => e.priceSale > 0 ? <div className='point-product d-flex flex-row justify-content-between pb-3'>
                                                                                <div className="gray small">{e.title}</div>
                                                                                <div className="gray small">- {customPrice((e.priceSale * e.count) - (e.price * e.count))} ₽</div>
                                                                            </div> : null)
                                                                        }
                                                                    </div>
                                                                </Collapse>
                                                            </>
                                                        }
                                                        {
                                                            cart.point > 0 && <>
                                                                <div className='cursor-pointer d-flex flex-row justify-content-between pb-3' onClick={() => setOpenPoint(!openPoint)} aria-expanded={openPoint}>
                                                                    <div>Начисление баллов</div>
                                                                    <div className="d-flex align-items-center pe-3 pe-sm-0">{cart.point} Б
                                                                        {
                                                                            openPoint ?
                                                                                <VscChevronUp color="#999" className='point-icon fs-12' />
                                                                                :
                                                                                <VscChevronDown color="#999" className='point-icon fs-12' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Collapse in={openPoint}>
                                                                    <div>
                                                                        {
                                                                            cart.products && cart.products.map(e => e.pointProcent > 0 ? <div className='point-product d-flex flex-row justify-content-between pb-3'>
                                                                                <div className="gray small">{e.title}</div>
                                                                                <div className="gray small">+ {customPrice((e.price * e.count) * e.pointProcent / 100)} Б</div>
                                                                            </div> : null)
                                                                        }
                                                                    </div>
                                                                </Collapse>
                                                            </>
                                                        }
                                                    </div>
                                                    <hr className='order-2 order-lg-3' />
                                                </div>
                                            }
                                            <div className='order-1 order-lg-4 fw-6 fs-13 d-flex align-items-center justify-content-between py-lg-2'>
                                                <div>Итого</div>
                                                <div>{customPrice(cart.total)} ₽</div>
                                            </div>
                                            <Link to={CHECKOUT_ROUTE} className='order-4 btn-1 fs-11 w-100 px-4 mt-2'><span>Перейти к оформлению</span></Link>
                                        </div>
                                        {/* <aside className='d-none d-lg-block mt-4'></aside>
                                    <aside className='d-none d-lg-block mt-4'></aside> */}
                                    </Col>
                                </Row>
                            </>
                            : <>
                                <div className='d-flex align-items-center d-md-block mb-3 mb-sm-4 mb-md-0'>
                                    <Link to="/" className="btn-4 fs-11 px-3 mb-md-5">
                                        <VscArrowLeft className='fs-12' />
                                        <span className='ms-2 d-none d-md-inline'>Назад на главную</span>
                                    </Link>
                                </div>
                                <div className='d-flex flex-column align-items-center py-5'>
                                    <h2 className='fs-20'>Ваша корзина ещё пуста</h2>
                                    <p className='text-center fs-13 mb-4 mb-sm-5'>Воспользуйтесь поиском или перейдите в каталог, чтобы заполнить её товарами</p>
                                    <Link to='/catalog' className='btn-1 fs-13'>Перейти в каталог</Link>
                                </div>
                            </>
                    }
                </section>

                {/* <ProductsSlider className='d-none d-lg-block' /> */}
            </Container>
            <CustomModal isShow={openModal} setIsShow={setOpenModal}>
                <div className='text-center'>
                    <h5 className='text-center fs-15 mb-2'>Вы уверены в очистке корзины?</h5>
                    <p className='fs-12 gray mb-4'>Все товары и настройки будут удалены</p>
                    <div className='d-flex flex-row'>
                        <button className='order-4 btn-1 fs-11 w-100 px-4 me-3 mt-2' type="button" onClick={() => removeAllProducts()}><span>Да, продолжить</span></button>
                        <button className='order-4 btn-3 fs-11 w-100 px-4 mt-2' type="button" onClick={() => setOpenModal(!openModal)}><span>Отмена</span></button>
                    </div>
                </div>
            </CustomModal>
        </main>
    )
})

export default Cart
