import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { VscArrowLeft } from "react-icons/vsc";
import { Link, useParams } from 'react-router-dom';
import ArticlesSlider from '../components/ArticlesSlider';
import { getArticle } from '../http/articleAPI';
import Loading from './../components/loading/Loading';

export default function Article(props) {
    const { articleId } = useParams()
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getArticle(articleId)
            .then(data => {
                if (data && data.status) {
                    setData(data.article)
                }
            }).finally(() => setLoading(false));
    }, [])

    if (loading) {
        return <Loading full/>
    }

    return (
        <main>
            <Container className='mb-6'>
                <section className='mb-4 mb-sm-5'>
                    <div className='d-flex align-items-center d-md-block mb-3 mb-sm-4 mb-md-0'>
                        <Link to="/articles" className="btn-4 fs-11 px-3 mb-md-5">
                            <VscArrowLeft className='fs-12' />
                            <span className='ms-2 d-none d-md-inline'>Назад в статьи и советы</span>
                        </Link>
                        <h1 className='inner mb-0 mb-md-5 ms-2 ms-sm-4 ms-md-0'>{data.title}</h1>
                    </div>

                    <article className='full'>
                        <figure>
                            <img src="/imgs/img3.png" alt="Название новости" />
                            <figcaption>
                                <h2>{data.title}</h2>
                            </figcaption>
                        </figure>
                        {data.desc}
                    </article>
                </section>

                <section>
                    <h2>Читайте также</h2>
                    <ArticlesSlider />
                </section>
            </Container>
        </main>
    );
}