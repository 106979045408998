import { observer } from "mobx-react-lite"
import React, { useContext } from 'react'
import { FaRegTrashAlt } from "react-icons/fa"
import { IoAdd, IoRemove } from "react-icons/io5"
import { Link } from "react-router-dom"
import BtnFav from '../components/utils/BtnFav'
import { customPrice } from "../hooks/useGlobalHook"
import { Context } from "../index"
import { PRODUCT_ROUTE } from "../utils/consts"
import { toJS } from 'mobx'

export default observer(function CartItem(props) {
    var { item } = props
    item = toJS(item)

    const { cart } = useContext(Context)
    if (!item) {
        return null
    }
    const image = item.images ?? false

    const plusCount = () => {
        cart.setCartCountPlus(item)
    }
    const minusCount = () => {
        cart.setCartCountMinus(item)
    }
    const removeProduct = () => {
        cart.removeCartProduct(item)
    }
    const setCount = (count) => {
        if (count < 0 || count > 10000) {
            return false
        }
        cart.setCartCount(item, Number(count))
    }

    return (
        <div className='cart-item position-relative'>
            <Link to={PRODUCT_ROUTE + '/' + item.id}>
                <img src={image ? process.env.REACT_APP_API_URL + 'products/' + image : '/imgs/img2.png'} alt={item.title} />
            </Link>
            <div className='info'>
                <div className='order-1 flex-1 pe-3'>
                    <div className='top-left'>
                        {
                            (item.saleProcent > 0) &&
                            <div className='discount'>-{item.saleProcent}%</div>
                        }
                        {
                            (item.pointProcent > 0) &&
                            <div className='bonus'>{item.pointProcent}% бонусов</div>
                        }
                    </div>
                    {item.code > 0 && <div className='fw-6 mb-1 mb-md-2 mb-xl-3'><span className='light-gray'>Код:</span> {item.code}</div>}
                    <h5 className='mb-1 mb-md-2'>{item.title}</h5>
                    <div className='fs-11 gray maxtext'>{item.description}</div>
                </div>
                <div className='order-3 order-md-2 count-input'>
                    <button type='button' onClick={() => minusCount()}>
                        <IoRemove />
                    </button>
                    <input type="number" placeholder='1' min={1} value={item.count ?? 1} max={10000} onChange={(e) => setCount(e.target.value)} />
                    <button type='button' onClick={() => plusCount()}>
                        <IoAdd />
                    </button>
                </div>
                <div className='order-2 order-md-3 price'>
                    <div>{customPrice(item?.productPrice?.price ? item.productPrice.price * item.count : item?.price ? item.price * item.count : 0)} ₽</div>
                    {item.priceSale ? <div><del className="gray sale-price mb-2">{customPrice(item.priceSale * item.count) ?? 0} ₽</del></div> : null}
                </div>
            </div>
            <div className='btns'>
                <BtnFav data={item} />
                <button type='button' onClick={() => removeProduct()} className='fs-14 mt-3'><FaRegTrashAlt /></button>
            </div>
        </div>
    );
});