import React, { useContext, useEffect, useState } from 'react'
import { VscArrowLeft } from "react-icons/vsc"
import { NotificationManager } from 'react-notifications'
import { Link, useNavigate, useParams } from "react-router-dom"
import CompanyItem from '../../components/CompanyItem'
import Loading from "../../components/loading/Loading"
import NavPagination from '../../components/NavPagination'
import { createCompany, deleteCompany, editCompany, getCompanies, getCompany } from "../../http/userAPI"
import { Context } from "../../index"
import { PROFILE_ROUTE } from '../../utils/consts'

const Companies = () => {
    const { action, companyId } = useParams()
    const { user } = useContext(Context)
    const [data, setData] = useState(false)
    const [companyCreate, setCompanyCreate] = useState(false)
    const [companyEdit, setCompanyEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Мои компании"
        if (user) {
            user.user.type != 2 && navigate(PROFILE_ROUTE)
            getCompanies().then(e => e && setData(e)).finally(() => setLoading(false))
        }
    }, [])

    useEffect(() => {
        if (companyId && user) {
            getCompany(companyId).then(e => (e && e.company) && setCompanyEdit(e.company)).finally(() => setLoading(false))
        }
    }, [companyId]);

    const deleteClick = async () => {
        try {
            let data = await deleteCompany(companyEdit.id);
            if (data) {
                NotificationManager.success('Компания успешно удалена');
                navigate(PROFILE_ROUTE + '/companies')
            }
        } catch (e) {
            if (e.response && e.response.data) {
                NotificationManager.error(e.response.data.message);
            } else {
                NotificationManager.error(e);
            }
        }
    }
    if (loading) {
        return <Loading full/>
    }
    if (action && action == 'create') {

        const change = (e) => {
            if (e.target.type == 'checkbox') {
                setCompanyCreate({ ...companyCreate, [e.target.name]: e.target.checked })
            } else {
                setCompanyCreate({ ...companyCreate, [e.target.name]: e.target.value })
            }
        }

        const submit = async (e) => {
            try {
                e.preventDefault()
                if (!companyCreate || !companyCreate.name || !companyCreate.inn || !companyCreate.ogrn) {
                    NotificationManager.error('Заполните обязательные поля')
                    return
                }
                let data = await createCompany(companyCreate);
                if (data) {
                    NotificationManager.success('Компания успешно добавлена')
                    navigate(PROFILE_ROUTE + '/companies')
                }
            } catch (e) {
                if (e.response && e.response.data) {
                    NotificationManager.error(e.response.data.message)
                } else {
                    NotificationManager.error(e)
                }
            }
        }

        return (
            <>
                <div className='d-flex align-items-center mb-3 mb-sm-4'>
                    <Link to={PROFILE_ROUTE + '/companies'} className="btn-4 fs-11 px-2 px-sm-3">
                        <VscArrowLeft className='fs-12' />
                    </Link>
                    <h1 className='inner mb-0 ms-2 ms-sm-4'>Добавить компанию</h1>
                </div>
                <form onSubmit={submit} onChange={change}>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='Введите полное название' name="name" value={companyCreate.name ?? ''} className='fs-11 fw-5' required />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='ИНН' name="inn" value={companyCreate.inn ?? ''} className='fs-11 fw-5' required />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='ОГРН(ИП)' name="ogrn" value={companyCreate.ogrn ?? ''} className='fs-11 fw-5' />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label>
                            <input type='checkbox' name="main" checked={companyCreate.main} />
                            <span className='ms-2'>сделать по умолчанию</span>
                        </label>
                    </div>
                    <div className='d-flex'>
                        <button type='submit' className='btn-1 fs-11'>
                            <span>Сохранить</span>
                        </button>
                    </div>
                </form>
            </>
        );
    } else if (action && action == 'edit') {

        const change = (e) => {
            if (e.target.type == 'checkbox') {
                setCompanyEdit({ ...companyEdit, [e.target.name]: e.target.checked })
            } else {
                setCompanyEdit({ ...companyEdit, [e.target.name]: e.target.value })
            }
        }
        const submit = async (e) => {
            try {
                e.preventDefault()
                if (!companyEdit || !companyEdit.name || !companyEdit.inn || !companyEdit.ogrn) {
                    NotificationManager.error('Заполните обязательные поля')
                    return
                }
                let data = await editCompany(companyEdit);
                if (data) {
                    NotificationManager.success('Компания успешно изменена')
                    navigate(PROFILE_ROUTE + '/companies')
                }
            } catch (e) {
                if (e.response && e.response.data) {
                    NotificationManager.error(e.response.data.message)
                } else {
                    NotificationManager.error(e)
                }
            }
        }

        return (
            <>
                <div className='d-flex align-items-center mb-3 mb-sm-4'>
                    <Link to={PROFILE_ROUTE + '/companies'} className="btn-4 fs-11 px-2 px-sm-3">
                        <VscArrowLeft className='fs-12' />
                    </Link>
                    <h1 className='inner mb-0 ms-2 ms-sm-4'>Редактирование компании</h1>
                </div>
                <form onSubmit={submit} onChange={change}>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='Введите полное название' name="name" value={companyEdit.name} className='fs-11 fw-5' required />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='ИНН' name="inn" value={companyEdit.inn} className='fs-11 fw-5' required />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='ОГРН(ИП)' name="ogrn" value={companyEdit.ogrn} className='fs-11 fw-5' />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label>
                            <input type='checkbox' name="main" checked={companyEdit.main} />
                            <span className='ms-2'>сделать по умолчанию</span>
                        </label>
                    </div>
                    <div className='d-flex'>
                        <button type='submit' className='btn-1 fs-11'>
                            <span>Сохранить изменения</span>
                        </button>
                        <button type='button' onClick={deleteClick} className='btn-3 fs-11 ms-4'>
                            <span>Удалить компанию</span>
                        </button>
                    </div>
                </form>
            </>
        );
    } else if (data && data.сompanies.length > 0) {
        return <div>
            <div className='d-flex align-items-center mb-3 mb-sm-4'>
                <Link to={PROFILE_ROUTE} className="btn-4 fs-11 px-2 px-sm-3"><VscArrowLeft className='fs-12' /></Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4'>Мои компании</h1>
            </div>
            <Link to='create' className='btn-1 fs-14 w-100 mb-3'>
                <span>Добавить компанию</span>
            </Link>
            {data.сompanies.map((item, index) => <CompanyItem item={item} index={index} />)}
            <NavPagination pagination={data.pagination} link='/companies' />
        </div>
    } else {
        return <>
            <div className='d-flex align-items-center mb-3 mb-sm-4'>
                <Link to={PROFILE_ROUTE} className="btn-4 fs-11 px-2 px-sm-3">
                    <VscArrowLeft className='fs-12' />
                </Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4'>Мои компании</h1>
            </div>
            <div className='box-2 p-5'>
                <h4 className='fw-7 text-center mb-4'>Ваш список копаний пуст</h4>
                <Link to={PROFILE_ROUTE + '/companies/create'} className='btn-1 mx-auto'><span>Добавить компанию</span></Link>
            </div>
        </>
    }
}

export default Companies
