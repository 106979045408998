import React, { useEffect } from 'react'

const Policy = () => {
    useEffect(() => {
        document.title = "Пользовательское соглашение"
    }, [])
    return (
        <main className='pt-4 pt-lg-5'>
            <section id="sec-8" className="mb-8">
                <div className="container">
                    <h1 className='inner'>Пользовательское соглашение</h1>
                    {/* <p>(действует с 01 января 2018 года)</p>
                    <h5>1. Статус Пользовательского соглашения</h5>
                    <p>1.1. Настоящее Пользовательское соглашение является юридически обязательным соглашением между Посетителем, Пользователем и Компанией, предметом которого является предоставление Компанией Посетителю, Пользователю доступа к ознакомлению с Сайтом и возможность использовать функционал Сайта для совершения заказа Товаров после регистрации Пользователя на Сайте.</p>
                    <p>1.2. Посетитель и Пользователь обязаны полностью ознакомиться с настоящим Пользовательским соглашением до момента использования Сайта.</p>
                    <p>1.3. Посетитель, Пользователь принимают условия настоящего Пользовательского соглашения, в полном объеме и без каких-либо изъятий (исключений).</p>
                    <p>1.4. в случае несогласия с перечисленными условиями Посетитель и Пользователь должны воздержаться от использования Сайта.</p>
                    <br />
                    <h5>2. Термины и определения</h5>
                    <p>[Для целей настоящей оферты термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе.]</p>
                    <p>2.1. в настоящем Пользовательском соглашении, если из контекста не следует иное, нижеприведенные термины с заглавной буквы имеют следующие значения:</p>
                    <p>2.1.1. «Компания» — юридическое лицо, ИП Вард Амалия Анатольевна  (ИНН  165123295890; ОГРН 318169000012522), созданное в соответствии с законодательством Российской Федерации и осуществляющее предпринимательскую деятельность по продаже Товаров, в том числе дистанционным способом.</p>
                    <p>2.1.2. «Посетитель» — любое дееспособное лицо, желающее ознакомится с содержанием Сайта, или намеренное заказать или приобрести Товары Компании исключительно для личных и иных нужд, не связанных с осуществлением предпринимательской деятельности.</p>
                    <p>2.1.3. «Пользователь» — Посетитель, который уже зарегистрирован на Сайте (имеет личную учетную запись).</p>
                    <p>2.1.4. «Товары» — продукция Компании, представленная на Сайте.</p>
                    <p>2.1.5. «Сайт, Веб – сайт» —  веб-страница, содержащая контент (информацию) Продавца по оказанию услуг по договору купли-продажи Товаров дистанционным способом, имеющая уникальный адрес refettorio.cafe , в том числе Мобильная версия, а также работающая по протоколу HTTPS для защиты персональных данных Покупателей.</p>
                    <br />
                    <h5>3. Предмет Пользовательского Соглашения</h5>
                    <p>3.1. Предметом настоящего Пользовательского соглашения являются порядок и правила использования сервисов Сайта, установленные Компанией для Посетителей и Пользователей Сайта.</p>
                    <p>3.2. Компания гарантирует Пользователям возможность совершить заказ Товаров и его оплату через Сайт только в случае исправной работы всех коммуникационных сетей и не несет ответственности за неисполнение заказа Товаров в случае технических проблем на Сайте.</p>
                    <br />
                    <h5>4. Регистрация и предоставление услуг</h5>
                    <p>4.1. для получения права оформить заказ Товаров через Сайт Пользователь обязуется осуществить регистрацию учетной записи Пользователя на Сайте.</p>
                    <p>4.2. для регистрации на Сайте Пользователю следует произвести следующие действия:</p>
                    <p>4.2.1. ввести в форму абонентский номер телефона в федеральном формате (+ХХХХХХХХХХХ);</p>
                    <p>4.2.2. указанный Пользователем при регистрации абонентский номер телефона будет использоваться в качестве имени Пользователя (логин) при использовании Сайта;</p>
                    <p>4.2.3. ввести пароль, предоставляемый Пользователю в виде SMS-сообщения на указанный им абонентский номер;</p>
                    <p>4.2.4. указать фамилию, имя, отчество; дату рождения; пол; адрес доставки; телефон; адрес электронной почты;</p>
                    <p>4.3. Пользователь согласен на получение от Компании уведомлений о проводимых акциях и рекламных материалов по сетям электросвязи. Согласие может быть отозвано Пользователем в любой момент путем отказа от получения уведомлений в личном кабинете или направления отказа Компании.</p>
                    <p>4.4. в случае несогласия с перечисленными условиями Пользователь должен воздержаться от использования сервисов Сайта.</p>
                    <p>4.5. Пользователю запрещается передавать данные своей учетной записи третьим лицам. В случае передачи Пользователем своего логина и/или пароля третьему лицу, Пользователь несет ответственность за несанкционированные действия третьего лица, как за свои собственные.</p>
                    <p>4.6. Пользователь несет ответственность за точность и правильность, полноту и достоверность указания своих личных данных при регистрации учетной записи на Сайте и за последствия в виде убытков, которые могут возникнуть у Пользователя, в случае некорректного ввода указанных сведений.</p>
                    <p>4.7. Пользователь обязан незамедлительно уведомить Компанию о любом случае несанкционированного доступа к учетной записи Пользователя, а также о любом случае нарушения безопасности своего логина и пароля (утеря, передача данных третьим лицам, другое).</p>
                    <p>4.8. Любые действия, совершенные на Сайте с использованием логина и пароля Пользователя, считаются совершенными соответствующим Пользователем.</p>
                    <p>4.9. Пользователь несет личную ответственность за любую информацию, которая размещается на Сайте посредством его учетной записи.</p>
                    <br />
                    <h5>5. Условия использования информации, размещенной на Сайте</h5>
                    <p>5.1. Сайт включает, но не ограничивается, следующим: тексты, фотографии, графические изображения, товарные знаки и иные, охраняемые законом, материалы.</p>
                    <p>5.2. Все содержание Сайта охраняется авторским правом в соответствии с действующим гражданским законодательством Российской Федерации.</p>
                    <p>5.3. Авторское право на использование содержания Сайта принадлежит Компании.</p>
                    <p>5.4. Компания предоставляет Посетителю и/или Пользователю неисключительную лицензию на использование содержания Сайта в пределах, установленных настоящим Пользовательским соглашением.</p>
                    <p>5.5. Посетитель и/или Пользователь обязуется использовать Сайт только в законных целях и способами, не нарушающими права третьих лиц.</p>
                    <p>5.6. Компания не несет ответственность за вред, причиненный технике Посетителя и/или Пользователя в случае, если это произошло в результате перехода по гипертекстуальным ссылкам, размещенным на Сайте.</p>
                    <br />
                    <h5>6. Полномочия Компании</h5>
                    <p>6.1. Компания оставляет за собой право без какого-либо специального уведомления вносить изменения в настоящее Пользовательское соглашение, в связи с чем Посетитель и/или Пользователь обязуется самостоятельно контролировать наличие изменений в настоящим Пользовательском соглашении. Новая редакция Пользовательского соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Пользовательского соглашения. Действующая редакция Пользовательского соглашения находится на Сайте.</p>
                    <p>6.2. Компания оставляет за собой право изменять Сайт, в том числе изменять или добавлять в его структуру разделы, менять дизайн и совершать прочие действия, направленные на повышение функциональности Сайта и удобства совершения заказа Товаров Пользователями и ознакомления Посетителя и/или Пользователя с информацией, размещенной Компанией на Сайте.</p>
                    <p>6.3. Компания вправе произвести блокировку, исключение, удаление информации, размещенной Пользователем, без согласия последнего, если такая информация не отвечает требованиям действующего российского законодательства и положениям настоящего Пользовательского соглашения.</p>
                    <p>6.4. Компания вправе произвести приостановление оказания услуг пользования Сайтом, либо отказать Пользователю в возможности использования отдельных ресурсов Сайта, либо отменить регистрацию учетной записи Пользователя, если у Компании есть основания полагать, что предоставленная Пользователем информация о своих персональных данных недостоверна (неверна, искажена).</p>
                    <p>6.5. Компания вправе осуществить действия по удалению учетной записи Пользователя в случае систематического нарушения последним положений настоящего Пользовательского соглашения.</p>
                    <br />
                    <h5>7. Заключительные положения</h5>
                    <p>7.1. Настоящее Пользовательское соглашение регулируется нормами действующего российского законодательства.</p>
                    <p>7.2. Настоящее Пользовательское соглашение распространяется на Посетителей и Пользователей.</p>
                    <p>7.3. Все возможные споры относительно настоящего Пользовательского соглашения разрешаются согласно нормам действующего российского законодательства.</p> */}
                </div>
            </section>
        </main>
    );
};

export default Policy;
