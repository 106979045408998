import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { customPrice } from "../hooks/useGlobalHook";
import { PRODUCT_ROUTE } from "../utils/consts";
import AddToCart from "./utils/AddToCart";
import BtnFav from "./utils/BtnFav";

export default observer(function ProductCard(props) {
  const { data } = props;
  const image = data.images;
  return (
    <div className="product-card">
      <div className="position-relative mb-2 mb-sm-3">
        <Link to={PRODUCT_ROUTE + "/" + data.id}>
          <img
            src={
              image
                ? process.env.REACT_APP_API_URL + "products/" + image
                : "/imgs/img2.png"
            }
            alt={data.title}
          />
        </Link>
        <div className="top-left">
          {data.code > 0 && (
            <div className="fw-6 light-gray mb-1">
              Код: <span className="black">{data.code}</span>
            </div>
          )}
          {data.priceSale > 0 && (
            <div className="discount">
              -
              {parseInt(
                ((parseInt(data.priceSale) - parseInt(data?.productPrice?.price)) /
                  ((parseInt(data.priceSale) + parseInt(data?.productPrice?.price)) / 2)) *
                100
              )}
              %
            </div>
          )}
        </div>
        <BtnFav data={data} />
        {data.pointProcent > 0 && (
          <div className="bonus">До {data.pointProcent} % бонусами</div>
        )}
      </div>
      <h5 className="fw-4 mb-1 mb-sm-2">
        <Link to={props.link}>{data.title}</Link>
      </h5>
      <div className="d-flex align-items-baseline mb-2 mb-md-3 mb-xxl-3">
        {data.priceSale ? (
          <>
            <del className="gray fs-10 fw-4 me-2">
              {customPrice(data.priceSale)} ₽
            </del>
            <span className="fs-14 fw-6">{customPrice(data?.productPrice?.price)}</span>
          </>
        ) : (
          <span className="fs-14 fw-6">{customPrice(data?.productPrice?.price)}</span>
        )}
        <span className="ms-2 fw-6 fs-12">₽</span>
      </div>
      <AddToCart product={data} />
    </div>
  );
});
