import React from 'react';

export default function Sort(props) {
    const { value, onChange } = props
    return <select className='fs-11 fw-7' value={value} onChange={(e) => onChange(e.target.value)}>
        <option value={0}>Сортировать по</option>
        <option value={1}>Сначала дешевые</option>
        <option value={2}>Сначала дорогие</option>
        <option value={3}>Сначала новинки</option>
    </select>
}