import React, { useContext, useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { NotificationManager } from 'react-notifications'
import { Link, useNavigate } from "react-router-dom"
import InputPassword from '../../components/utils/InputPassword'
import { Context } from "../../index"
import { registration } from "../../http/userAPI"
import InputMask from 'react-input-mask'
import { LOGIN_ROUTE, TERMS_ROUTE, ACTIVATE_ROUTE, PROFILE_ROUTE } from '../../utils/consts'

const Registration = () => {
      const { user } = useContext(Context)
      const navigate = useNavigate()
      const [phone, setPhone] = useState('')
      const [password, setPassword] = useState('')
      const [email, setEmail] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('')
      const [agree, setAgree] = useState(true)

      useEffect(() => {
            if (user.isAuth) {
                  navigate(PROFILE_ROUTE)
            }
      }, []);

      const submit = async (e) => {
            try {
                  e.preventDefault()
                  if (!password || password.length < 4) {
                        NotificationManager.error('Пароль должен состоять не менее, чем из 4 символов')
                        return;
                  }
                  if (!confirmPassword || confirmPassword != password) {
                        NotificationManager.error('Пароли не совпадают')
                        return;
                  }
                  if (!phone || phone.length < 16) {
                        NotificationManager.error('Введите номер телефона (Не менее 10 цифр)')
                        return;
                  }
                  if (!email || email.length < 3) {
                        NotificationManager.error('Введите корректный Email')
                        return;
                  }

                  let formData = { email, phone, password, confirmPassword, agree }

                  let data = await registration(formData)

                  if (data) {
                        NotificationManager.success('На ваш номер отправлен код подтверждения')
                        navigate(ACTIVATE_ROUTE, { state: formData })
                  } else {
                        NotificationManager.error('Произошла ошибка при регистрации, повторите попытку позже')
                  }
            } catch (e) {
                  NotificationManager.error(e.response.data.message)
            }
      }
      return (
            <main>
                  <Container>
                        <section>
                              <h1 className='inner text-center'>Регистрация</h1>
                              <Row className='justify-content-center'>
                                    <Col md={8} lg={6}>
                                          <form onSubmit={submit}>
                                                <label htmlFor='phone' className='fs-13 fw-7 mb-2'>Номер телефона</label>
                                                <InputMask mask="+7 999 999 99 99" placeholder="+7 000 000 00 00" name="phone" maskChar="" defaultValue={phone} value={phone} onChange={(e) => setPhone(e.target.value)} className='fs-11 fw-5 mb-4' />

                                                <label htmlFor='email' className='fs-13 fw-7 mb-2'>Email</label>
                                                <input type='email' defaultValue={email} value={email} onChange={(e) => setEmail(e.target.value)} id="email" placeholder='Электронная почта' className='fs-11 fw-5 mb-3 mb-sm-4' />

                                                <div className='fs-13 fw-7 mb-2'>Пароль</div>
                                                <InputPassword className="fs-11 fw-5 mb-4" defaultValue={password} value={password} onChange={(e) => setPassword(e.target.value)} />

                                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                                      <div className='fs-13 fw-7'>Повторите пароль</ div>
                                                      {(password && confirmPassword === password) ? <div className='success-color fs-09'>Пароли совпадают</div> : password && confirmPassword && <div className='error-color fs-09'>Пароли не совпадают</div>}
                                                </div>
                                                <InputPassword className="fs-11 fw-5 mb-4" defaultValue={confirmPassword} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                <label className='fs-11 justify-content-sm-center mb-4'>
                                                      <input type='checkbox' onChange={(e) => setAgree(e.target.checked)} checked={agree} />
                                                      <div className='ms-2'>Я согласен на обработку <Link className='blue' to={TERMS_ROUTE}>персональных данных</Link></div>
                                                </label>

                                                <button type='submit' disabled={phone.length < 16 || password.length < 4 || confirmPassword.length < 4 || confirmPassword != password || email.length < 3 || !agree} className='btn-1 mx-sm-auto fs-13 mt-3'><span>Зарегестрироваться</span></button>

                                                <div className='mt-3 text-sm-center gray fw-5 fs-11'>У вас уже есть аккаунт? <Link to={LOGIN_ROUTE} className='d-inline link td-under'>Войти</Link></div>
                                          </form>
                                    </Col>
                              </Row>
                        </section>
                  </Container>
            </main>
      );
}

export default Registration
