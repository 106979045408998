
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";

export default function NavPagination(props) {
    const { pagination, link } = props
    const navigate = useNavigate();
    const handlePageClick = (event) => {
        navigate(link + "/" + (Number(event.selected) + 1));
    }

    return <nav className="pagination mt-4 mt-sm-5" >
        <ReactPaginate
            onPageChange={handlePageClick}
            activeClassName="active"
            breakLabel="..."
            previousLabel="Назад"
            nextLabel="Далее"
            pageRangeDisplayed={5}
            initialPage={pagination.initialPage - 1}
            pageCount={pagination.pageCount}
        />
    </nav>
}