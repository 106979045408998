import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import useIsMobile from '../../hooks/isMobile';
import PersonalAccountRouter from '../../routes/PersonalAccountRouter';

const Profile = () => {
    const { mobile } = useIsMobile();

    useEffect(() => {
        document.title = 'Профиль'
    }, [])

    return <main>
        <Container className='account mb-6'>
            <PersonalAccountRouter isMobile={mobile} />
        </Container>
    </main>
}

export default Profile
