import React, { useLayoutEffect } from 'react';
import { useLocation, useRoutes } from "react-router-dom";
import Layout from '../components/Layout';
import About from "../pages/About";
import Article from "../pages/Article";
import Articles from "../pages/Articles";
import Login from "../pages/auth/Login";
import Registration from "../pages/auth/Registration";
import RestorePassword from "../pages/auth/RestorePassword";
import Cart from "../pages/Cart";
import Catalog from "../pages/Catalog";
import Checkout from "../pages/Checkout";
import Contacts from "../pages/Contacts";
import Delivery from "../pages/Delivery";
import PayError from "../pages/Error";
import Favorites from "../pages/Favorites";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Offer from "../pages/Offer";
import Policy from "../pages/Policy";
import Product from "../pages/Product";
import Profile from "../pages/profile";
import Sale from "../pages/Sale";
import Search from "../pages/Search";
import PaySuccess from "../pages/Success";
import Terms from "../pages/Terms";
import { ABOUT_ROUTE, ACTIVATE_ROUTE, ARTICLES_ROUTE, ARTICLE_ROUTE, CART_ROUTE, CATALOG_ROUTE, CHECKOUT_ROUTE, CONTACTS_ROUTE, DELIVERY_ROUTE, FAVORITES_ROUTE, LOGIN_ROUTE, OFFER_ROUTE, PAY_ERROR_ROUTE, PAY_SUCCESS_ROUTE, POLICY_ROUTE, PRODUCT_ROUTE, PROFILE_ROUTE, REG_ROUTE, RESTORE_PASSWORD_ROUTE, SALE_ROUTE, SEARCH_ROUTE, TERMS_ROUTE } from "../utils/consts";
import Activate from './../pages/auth/Activate';

export const routeList = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Home />, breadcrumb: 'Главная' },
      { path: CATALOG_ROUTE, element: <Catalog />, breadcrumb: 'Каталог' },
      { path: CATALOG_ROUTE + '/:catalogId', element: <Catalog />, breadcrumb: 'Каталог' },
      { path: CATALOG_ROUTE + '/:catalogId/:page', element: <Catalog />, breadcrumb: 'Каталог' },
      { path: PRODUCT_ROUTE + '/:productId', element: <Product />, breadcrumb: 'Товар' },
      { path: SEARCH_ROUTE, element: <Search />, breadcrumb: 'Поиск' },
      { path: SEARCH_ROUTE + '/:text', element: <Search />, breadcrumb: 'Поиск' },
      { path: SEARCH_ROUTE + '/:text/:page', element: <Search />, breadcrumb: 'Поиск' },
      { path: FAVORITES_ROUTE, element: <Favorites />, breadcrumb: 'Избранное' },
      { path: CONTACTS_ROUTE, element: <Contacts />, breadcrumb: 'Контактная информация' },
      { path: ARTICLES_ROUTE, element: <Articles />, breadcrumb: 'Статьи и советы' },
      { path: ARTICLE_ROUTE + '/:articleId', element: <Article />, breadcrumb: 'Статья' },
      { path: DELIVERY_ROUTE, element: <Delivery />, breadcrumb: 'Доставка' },
      { path: CART_ROUTE, element: <Cart />, breadcrumb: 'Корзина' },
      { path: CHECKOUT_ROUTE, element: <Checkout />, breadcrumb: 'Оформление заказа' },
      { path: REG_ROUTE, element: <Registration />, breadcrumb: 'Регистрация' },
      { path: ACTIVATE_ROUTE, element: <Activate />, breadcrumb: 'Подтверждение профиля' },
      { path: LOGIN_ROUTE, element: <Login />, breadcrumb: 'Вход в личный кабинет' },
      { path: RESTORE_PASSWORD_ROUTE, element: <RestorePassword />, breadcrumb: 'Восстановление пароля' },
      { path: PROFILE_ROUTE + '/*', element: <Profile />, breadcrumb: 'Личный кабинет' },
      { path: POLICY_ROUTE, element: <Policy />, breadcrumb: 'Политика конфиденциальности' },
      { path: TERMS_ROUTE, element: <Terms />, breadcrumb: 'Пользовательское соглашение' },
      { path: OFFER_ROUTE, element: <Offer />, breadcrumb: 'Договор оферты' },
      { path: ABOUT_ROUTE, element: <About />, breadcrumb: 'О нас' },
      { path: SALE_ROUTE, element: <Sale />, breadcrumb: 'Акции' },
      { path: PAY_SUCCESS_ROUTE, element: <PaySuccess />, breadcrumb: 'Успешная оплата' },
      { path: PAY_ERROR_ROUTE, element: <PayError />, breadcrumb: 'Ошибка при оплате' },
      { path: '*', element: <NotFound />, breadcrumb: 'Страница не найдена' },
    ],
  },
];

export default function AppRouter() {
  const Wrapper = ({ children }) => {
    const { pathname } = useLocation();
    useLayoutEffect(() => document.documentElement.scrollTo(0, 0), [pathname])
    return children
  }

  const element = useRoutes(routeList)

  return (
    <Wrapper>
      {element}
    </Wrapper>
  );
}