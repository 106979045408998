import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { routeList } from '../../routes/AppRouter';
import { NavLink } from 'react-router-dom';

export default function Breadcrumbs(props) {
    const { breadcrumb } = props
    const breadcrumbs = useBreadcrumbs(routeList);
    if (breadcrumb) {
        breadcrumbs.pop()
        breadcrumbs.push(breadcrumb)
    }

    return (
        <nav className="breadcrumbs">
            <ul>
                {
                    breadcrumbs.map(({ match, breadcrumb }) => (
                        <li key={match.pathname}>
                            <NavLink to={match.pathname}>{breadcrumb}</NavLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
}