import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";

export const registration = async (formData) => {
    const { data } = await $host.post('/user/registration', formData)
    return data
}
export const activate = async (formData) => {
    const { data } = await $host.post('/user/activate', formData)
    return data
}
export const newPassword = async (formData) => {
    const { data } = await $host.post('/user/newPassword', formData)
    return data
}
export const login = async (phone, password) => {
    var { data } = await $host.post('/user/login', { phone, password })
    if (data && data.status && data.token) {
        localStorage.setItem('token', data.token)
        data.token = jwt_decode(data.token)
        return data
    } else {
        return data
    }
}
export const edit = async (array) => {
    const { data } = await $authHost.post('/user/edit', array)
    return data
}
export const getAddress = async (id) => {
    const { data } = await $authHost.post('/user/getAddress', { id })
    return data
}
export const getAddresses = async (main) => {
    const { data } = await $authHost.post('/user/getAddresses', { main })
    return data
}
export const createAddress = async (array) => {
    const { data } = await $authHost.post('/user/createAddress', array)
    return data
}
export const editAddress = async (array) => {
    const { data } = await $authHost.post('/user/editAddress', array)
    return data
}
export const deleteAddress = async (id) => {
    const { data } = await $authHost.post('/user/deleteAddress', { id })
    return data
}
export const getCompany = async (id) => {
    const { data } = await $authHost.post('/user/getCompany', { id })
    return data
}
export const getCompanies = async (main) => {
    const { data } = await $authHost.post('/user/getCompanies', { main })
    return data
}
export const createCompany = async (array) => {
    const { data } = await $authHost.post('/user/createCompany', array)
    return data
}
export const editCompany = async (array) => {
    const { data } = await $authHost.post('/user/editCompany', array)
    return data
}
export const deleteCompany = async (id) => {
    const { data } = await $authHost.post('/user/deleteCompany', { id })
    return data
}
export const getPoints = async () => {
    const { data } = await $authHost.post('/user/getAllPoints')
    return data
}
export const check = async () => {
    let token = localStorage.getItem('token')
    if (!token) {
        return false
    }
    const { data } = await $authHost.get('/user/auth')
    return data
}
export const updatePoint = async () => {
    const { data } = await $authHost.post('/user/updatePoint')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}