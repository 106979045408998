export function customPrice(value) {
    return parseInt(Number(value)).toLocaleString();
}
export function paymentText(value) {
    let data = {
        card: 'Банковской картой',
        cash: 'Наличный расчет',
        online: 'Онлайн оплата',
        ip: 'Расчетный счет',
    }
    return data[value] ?? data['card']
}
export function statusText(value) {
    let data = {
        0: 'В процессе',
        1: 'Доставлен',
        2: 'Отменен',
        3: 'Доставка',
        4: 'Выдан',
    }
    return data[value] ?? data[0]
}
export function getFilterData() {
    const filterLocal = JSON.parse(localStorage.getItem('filter'))
    let data = filterLocal ?? {
        max: 0,
        min: 0,
        count: 0,
        pageCount: 16,
        sort: 0
    }
    return data
}
export const filterData = getFilterData()