import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

export default function BonusOperation(props) {
    const { item } = props
    return (
        <Row className='bonus-operation gx-0 align-items-center'>
            <Col>
                <div>{item.desc}</div>
                <div className='fs-09 gray'>{moment(item.createdAt).fromNow()}</div>
            </Col>
            <Col>
                <div className='fs-13 fw-5 d-flex align-items-center justify-content-end'>
                    <span className={(item.type == 'minusOrder') ? 'danger-color' : 'success-color'}>{(item.type == 'minusOrder') ? '-' : '+'} {item.point}</span>
                    <span className='main-color ms-2'>Б</span>
                </div>
            </Col>
        </Row>
    );
}