import { observer } from "mobx-react-lite";
import Range from 'rc-slider';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import { VscChevronRight, VscChromeClose } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import CategoryCard from '../components/CategoryCard';
import Error from "../components/Error";
import NavPagination from '../components/NavPagination';
import ProductCard from '../components/ProductCard';
import Sort from '../components/Sort';
import SortPageCount from "../components/SortPageCount";
import Breadcrumbs from '../components/utils/Breadcrumbs';
import { filterData } from "../hooks/useGlobalHook";
import { getCategories, getCategory } from "../http/categoryAPI";
import { getProducts } from "../http/productAPI";
import { CATALOG_ROUTE } from "../utils/consts";
import Loading from './../components/loading/Loading';

SwiperCore.use([Navigation, Pagination])

export default observer(function Catalog() {
    const { catalogId, page } = useParams()
    const [show, setShow] = useState(false)
    const [data, setData] = useState({})
    const [category, setCategory] = useState(false)
    const [categories, setCategories] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState(filterData)

    const getData = () => {
        setLoading(true);
        if (catalogId) {
            getCategory(catalogId).then(data => {
                if (data && data.status) {
                    document.title = data.category && data.category.title ? data.category.title : 'Каталог'
                    setCategory(data.category)
                }
            });

            getProducts(catalogId, page ?? 1, filter.pageCount, filter).then(data => {
                if (data && data.status) {
                    setData(data)
                    setFilter({ ...filter, min: filter.min > 0 ? filter.min : data.min, max: filter.max > 0 ? filter.max : data.max })
                }
            }).finally(() => setLoading(false));
        } else {
            getCategories().then(data => {
                if (data && data.status) {
                    setCategories(data.categories)
                }
            }).catch(() => setError(true)).finally(() => setLoading(false));
        }
    }
    const getProductsFilter = (e) => {
        e.preventDefault()
        if (filter) {
            getProducts(catalogId, 1, filter.pageCount, filter).then(data => {
                setData(data)
            })
            localStorage.setItem('filter', JSON.stringify(filter))
            setShow(false)
        }
    }
    const setFunctionSort = (sort) => {
        if (filter) {
            let newFilter = { ...filter, sort }
            setFilter(newFilter)
            getProducts(catalogId, 1, filter.pageCount, newFilter).then(data => {
                setData(data)
            })
            localStorage.setItem('filter', JSON.stringify(newFilter))
        }
    }
    const setFunctionPageCount = (pageCount) => {
        if (filter) {
            let newFilter = { ...filter, pageCount }
            setFilter(newFilter)
            getProducts(catalogId, 1, pageCount, newFilter).then(data => {
                setData(data)
            })
            localStorage.setItem('filter', JSON.stringify(newFilter))
        }
    }
    const resetFilter = () => {
        let newFilter = {
            max: data.max,
            min: data.min,
            count: 0,
            pageCount: 16,
            sort: 0
        }
        setFilter(newFilter)
        localStorage.setItem('filter', JSON.stringify(newFilter))
        getProducts(catalogId, 1, newFilter.pageCount, newFilter).then(data => {
            setData(data)
        })
    }
    useEffect(() => {
        document.title = "Каталог"
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [catalogId, page])

    if (error) {
        return <Error />
    }

    if (loading) {
        return <Loading full />
    }

    if (catalogId) {

        return (
            <>
                <main>
                    <Container className='mb-6'>
                        <Breadcrumbs breadcrumb={{ match: false, breadcrumb: category.title }} />

                        <h1 className='inner'>{category.title} <span className="fs-07 fw-3 gray">{data.count} шт</span></h1>

                        <Row className='mt-4 mt-md-5'>
                            <Col lg={3} className="position-relative d-none d-lg-block">
                                <form className='filter' onSubmit={getProductsFilter}>
                                    <div className='fs-11 fw-7 mb-4'>Фильтры</div>
                                    <fieldset>
                                        <legend>Цена ₽</legend>
                                        <Row md={2} className="gx-2 gx-xl-3 mb-3">
                                            <Col>
                                                <div className="position-relative">
                                                    <span className="input-left-text">от</span>
                                                    <input type='number' className='from fw-7 fs-11' placeholder='от' defaultValue={filter.min} value={filter.min} onChange={(e) => setFilter({ ...filter, min: e.target.value })} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="position-relative">
                                                    <span className="input-left-text">до</span>
                                                    <input type='number' className='from fw-7 fs-11' min={0} max={data.max} placeholder='до' defaultValue={filter.max} value={filter.max} onChange={(e) => setFilter({ ...filter, max: e.target.value })} />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Range range allowCross={false} defaultValue={[filter.min, filter.max]} value={[filter.min, filter.max]} onChange={(e) => setFilter({ ...filter, min: e[0], max: e[1] })} min={data.min} max={data.max} />
                                    </fieldset>

                                    {/* <fieldset>
                                        <legend>Торговые марки</legend>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Все марки</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' defaultChecked={true} />
                                            <span className='ms-2'>Марка 1</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Марка 2</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Марка 3</span>
                                        </label>
                                        <button type="button" className='light-gray'>Показать ещё 12</button>
                                    </fieldset>

                                    <fieldset>
                                        <legend>Страна производитель</legend>
                                        <label>
                                            <input type='checkbox' defaultChecked={true} />
                                            <span className='ms-2'>Россия</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Китай</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Узбекистан</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Япония</span>
                                        </label>
                                        <button type="button" className='light-gray'>Показать ещё 12</button>
                                    </fieldset>

                                    <fieldset>
                                        <legend>Цвет</legend>
                                        <label>
                                            <input type='checkbox' defaultChecked={true} />
                                            <span className='ms-2'>Цвет 1</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Цвет 2</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Цвет 3</span>
                                        </label>
                                        <label>
                                            <input type='checkbox' />
                                            <span className='ms-2'>Цвет 4</span>
                                        </label>
                                        <button type="button" className='light-gray'>Показать ещё 12</button>
                                    </fieldset> */}
                                    <button type="submit" className='btn-1 w-100 mb-3'>Применить фильтр</button>
                                    <button type="button" className='btn-3 w-100' onClick={() => resetFilter()}>Сбросить фильтры</button>
                                </form>
                            </Col>
                            <Col lg={9}>
                                <div className='d-lg-flex align-items-center justify-content-end'>
                                    {/* <div className='d-flex align-items-center'>
                                        <button type='button' className='btn-1 px-2 px-sm-3 py-1 py-sm-2 fw-4'><span>Новинки</span></button>
                                        <button type='button' className='btn-1 px-2 px-sm-3 py-1 py-sm-2 color-2 fw-4 ms-3 ms-xl-4'><span>Хиты</span></button>
                                        <button type='button' className='btn-1 px-2 px-sm-3 py-1 py-sm-2 color-3 fw-4 ms-3 ms-xl-4'><span>Распродажа</span></button>
                                    </div> */}
                                    <div className='mt-4 mt-lg-0 d-flex align-items-center justify-content-end'>
                                        <Sort value={filter.sort} onChange={setFunctionSort} />
                                        <SortPageCount value={filter.pageCount} onChange={setFunctionPageCount} />
                                        <button type='button' onClick={() => setShow(!show)} className='fs-11 fw-7 ms-auto d-lg-none'>Фильтры</button>
                                    </div>
                                </div>
                                <hr className='my-3 my-xl-4' />
                                <Row xs={2} md={3} lg={4} className="gy-4 gx-3 g-sm-5 g-md-4 g-xl-5">
                                    {data.products.map(item => <Col><ProductCard data={item} /></Col>)}
                                </Row>
                                <NavPagination pagination={data.pagination} link={CATALOG_ROUTE + '/' + category.id} />
                            </Col>
                        </Row>
                    </Container>
                </main>
                <Offcanvas show={show} onHide={setShow}>
                    <Offcanvas.Body>
                        <form className='filterMobile' onSubmit={getProductsFilter}>
                            <section className='main'>
                                <fieldset>
                                    <div className='top'>
                                        <button type='button' className='gray' onClick={() => setShow(!show)}>Назад</button>
                                        <legend>Цена</legend>
                                        <button type='button' className='gray'>Сбросить</button>
                                    </div>
                                    <ul>
                                        <li>
                                            <fieldset>
                                                <Row md={2} className="gx-2 gx-xl-3 mb-3">
                                                    <Col>
                                                        <div className="position-relative">
                                                            <span className="input-left-text">от</span>
                                                            <input type='number' className='from fw-7 fs-11' placeholder='от' defaultValue={filter.min} value={filter.min} onChange={(e) => setFilter({ ...filter, min: e.target.value })} />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="position-relative">
                                                            <span className="input-left-text">до</span>
                                                            <input type='number' className='from fw-7 fs-11' min={0} max={data.max} placeholder='до' defaultValue={filter.max} value={filter.max} onChange={(e) => setFilter({ ...filter, max: e.target.value })} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Range range allowCross={false} defaultValue={[filter.min, filter.max]} value={[filter.min, filter.max]} onChange={(e) => setFilter({ ...filter, min: e[0], max: e[1] })} min={data.min} max={data.max} />
                                            </fieldset>
                                        </li>
                                    </ul>
                                </fieldset>
                            </section>
                            <div className='bottom'>
                                <button type='submit' className='btn-1 fs-12 w-100'>
                                    <span>Применить фильтр</span>
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }

    return (
        <main>
            <Container className='mb-6'>
                <h1 className='inner mb-4 mb-md-5'>Каталог</h1>

                <Row xs={2} md={3} lg={4} className="g-2 g-sm-3 g-xl-4">
                    {
                        categories && categories.map(item => {
                            if ((item.category.count > 0 && item.category.count != '0') || item.subCategories.length > 0) {
                                return <Col>
                                    <CategoryCard item={item} />
                                </Col>
                            }
                        })
                    }
                </Row>
            </Container>
        </main>
    );
});