
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { VscArrowLeft } from "react-icons/vsc";
import { Link, useParams } from "react-router-dom";
import ArticlePreview from '../components/ArticlePreview';
import NavPagination from '../components/NavPagination';
import { getArticles } from '../http/articleAPI';
import { ARTICLES_ROUTE } from '../utils/consts';
import Loading from './../components/loading/Loading';

export default function Articles() {
    const { catalogId, page } = useParams()
    const [data, setData] = useState()
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);

    const getData = () => {
        getArticles(catalogId ?? 0)
            .then(data => {
                if (data && data.status) {
                    setData(data.articles)
                    setPagination(data.pagination)
                }
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        document.title = "Статьи"
        getData();
    }, [])

    useEffect(() => {
        getData();
    }, [catalogId, page])

    if (loading) {
        return <Loading full/>
    }

    return (
        <main>
            <Container className='mb-6'>
                <section>
                    <div className='d-flex align-items-center d-md-block mb-3 mb-sm-4 mb-md-0'>
                        <Link to="/" className="btn-4 fs-11 px-3 mb-md-5">
                            <VscArrowLeft className='fs-12' />
                            <span className='ms-2 d-none d-md-inline'>Назад на главную</span>
                        </Link>
                        <h1 className='inner mb-0 mb-md-5 ms-2 ms-sm-4 ms-md-0'>Статьи и советы</h1>
                    </div>

                    <Row xs={1} sm={2} lg={3} className='g-4'>
                        {data.map(item => <Col><ArticlePreview data={item} /></Col>)}
                    </Row>
                    <NavPagination pagination={pagination} link={ARTICLES_ROUTE} />

                </section>
            </Container>
        </main >
    );
}