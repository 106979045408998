import { $host, $authHost } from "./index";

export const getArticles = async (categoryId = 0, page = 1, limit = 20) => {
    const { data } = await $host.post('/article', { categoryId, page, limit })
    console.log(data)
    return data
}

export const getArticle = async (id) => {
    const { data } = await $host.post('/article/getOne', { id })
    return data
}

export const createArticle = async (array) => {
    const { data } = await $authHost.post('/article/create', array)
    return data
}