import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function Error() {
      const location = useLocation()
      return <main>
            <div className='container'>
                  <div class="d-flex flex-column align-items-center py-5">
                        <h2 class="fs-20">Нет соединения</h2>
                        <p class="text-center fs-13 mb-4 mb-sm-5">Отсутствует соединения с серверов, повторите попытку чуть позже.<br /> Если доступ не вернулся в течении 5-10 мин,<br />обратитесь в нашу тех поддержку по телефону <b><a href="tel:8(843)266-61-39">8(843)266-61-39</a></b></p>
                        <Link class="btn-1 fs-13" to={location.pathname}>Повторить попытку</Link>
                  </div>
            </div>
      </main>
}

