import React, { useContext } from 'react';
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

export default observer(function BtnFav(props) {
    const { favorite } = useContext(Context)
    const { data } = props
    const dataFavorite = favorite.checkFavorite(data)
    const btnFavoriteAdd = (dataFavorite) ? dataFavorite.status : false

    const addFavorite = () => {
        favorite.setFavorite(data)
    }
    return (
        <button type='button' className='btn-fav' onClick={addFavorite}>
            {
                (btnFavoriteAdd)
                    ? <FaHeart className='sec-color' size={22} />
                    : <FaRegHeart size={22} />
            }
        </button>
    );
})