import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter } from "react-router-dom"
import AppRouter from "./routes/AppRouter"
import { observer } from "mobx-react-lite"
import { Context } from "./index"
import { check } from "./http/userAPI"
import { NotificationContainer } from 'react-notifications'
import Loading from "./components/loading/Loading"
import Error from './components/Error'

const App = observer(() => {
    const { user } = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (user) {
            (async () => {
                let data = await check().catch(() => setError(true)).finally(() => setLoading(false))
                if (data && data.status && data.user) {
                    user.setUser(data.user)
                    user.setIsAuth(true)
                } else {
                    localStorage.removeItem('token');
                    user.setUser(false)
                    user.setIsAuth(false)
                }
            })()
        }
    }, [])

    if (error) {
        return <Error />
    }

    if (loading) {
        return <Loading full/>
    }

    return (
        <BrowserRouter>
            <AppRouter />
            <NotificationContainer />
        </BrowserRouter>
    );
});

export default App;
