import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import InputMask from 'react-input-mask'
import { NotificationManager } from 'react-notifications'
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../../index"
import { PROFILE_ROUTE, LOGIN_ROUTE } from '../../utils/consts'
import { login, activate } from "../../http/userAPI"

const Activate = () => {
      const { user } = useContext(Context)
      const info = useLocation()
      const navigate = useNavigate()
      const [key, setKey] = useState('')
      var formData = {
            phone: info.state ? info.state.phone : '',
            password: info.state ? info.state.password : '',
            key
      }

      useEffect(() => {
            if (user.isAuth || !info.state) {
                  navigate(LOGIN_ROUTE)
            }
      }, []);

      const submit = async (e) => {
            try {
                  e.preventDefault()
                  if (!key || key.length < 4) {
                        NotificationManager.error('Введите код подтверждения')
                        return;
                  }
                  if (!formData) {
                        NotificationManager.error('Нет обязательных данных')
                        return;
                  }
                  let dataActivate = await activate(formData)
                  if (dataActivate) {
                        let data = await login(formData.phone, formData.password)

                        if (data) {
                              NotificationManager.success('Вы успешно авторизованы')
                              user.setUser(data)
                              user.setIsAuth(true)
                              navigate(PROFILE_ROUTE)
                        } else {
                              navigate(LOGIN_ROUTE)
                        }
                  } else {
                        NotificationManager.error('Ошибка при подтверждении профиля')
                  }
            } catch (e) {
                  NotificationManager.error(e.response.data.message)
            }
      }

      return (
            <main>
                  <Container>
                        <section id="enter-page">
                              <h1 className='inner text-center'>Подтверждение профиля</h1>
                              <Row className='justify-content-center'>
                                    <Col md={8} lg={6}>
                                          <form onSubmit={submit}>
                                                <div className='fs-13 fw-4 mb-2 text-center'>Введите код подтверждения</div>
                                                <InputMask mask="9 9 9 9" placeholder="0 0 0 0" name="key" maskChar="" defaultValue={key} value={key} onChange={(e) => setKey(e.target.value)} className='text-center fs-17 fw-7 mb-4 input-key m-auto' />

                                                <div className='d-flex justify-content-sm-center align-items-center mt-3'>
                                                      <button type="submit" disabled={key.length < 7} className='btn-1 fs-13'><span>Отправить</span></button>
                                                </div>
                                          </form>
                                    </Col>
                              </Row>
                        </section>
                  </Container>
            </main>
      )
}

export default Activate
