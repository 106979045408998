import React, { useContext, useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { NotificationManager } from 'react-notifications'
import { Link, useNavigate } from "react-router-dom"
import InputPassword from '../../components/utils/InputPassword'
import { Context } from "../../index"
import { login } from "../../http/userAPI"
import InputMask from 'react-input-mask'
import { TERMS_ROUTE, PROFILE_ROUTE, REG_ROUTE, RESTORE_PASSWORD_ROUTE, ACTIVATE_ROUTE } from '../../utils/consts'

const Login = () => {
      const { user } = useContext(Context)
      const navigate = useNavigate()
      const [phone, setPhone] = useState('')
      const [password, setPassword] = useState('')
      const [agree, setAgree] = useState(true)

      useEffect(() => {
            if (user.isAuth) {
                  navigate(PROFILE_ROUTE)
            }
      }, []);

      const submit = async (e) => {
            try {
                  e.preventDefault()
                  if (!phone || phone.length < 16 || !password || password.length < 4) {
                        NotificationManager.error('Введите номер телефона и пароль')
                        return;
                  }
                  let data = await login(phone, password)

                  if (data.status && data.token) {
                        NotificationManager.success('Вы успешно авторизовались')
                        user.setUser(data.token)
                        user.setIsAuth(true)
                        navigate(PROFILE_ROUTE)
                  } else if (data.type == 'NOT_ACTIVATE') {
                        navigate(ACTIVATE_ROUTE, { state: { phone, password } })
                  } else {
                        NotificationManager.error('Неверно введен номер телефона или пароль')
                  }
            } catch (e) {
                  NotificationManager.error(e.response.data.message)
            }
      }
      return (
            <main>
                  <Container>
                        <section id="enter-page">
                              <h1 className='inner text-center'>Вход в личный кабинет</h1>
                              <Row className='justify-content-center'>
                                    <Col md={8} lg={6}>
                                          <form onSubmit={submit}>
                                                <label htmlFor='phone' className='fs-13 fw-7 mb-2'>Номер телефона</label>
                                                <InputMask mask="+7 999 999 99 99" placeholder="+7 000 000 00 00" name="phone" maskChar="" defaultValue={phone} value={phone} onChange={(e) => setPhone(e.target.value)} className='fs-11 fw-5 mb-4' />
                                                <div className='fs-13 fw-7 mb-2'>Пароль</ div>
                                                <InputPassword className="fs-11 fw-5 mb-4" defaultValue={password} value={password} onChange={(e) => setPassword(e.target.value)} />
                                                <label className='fs-11 justify-content-sm-center mb-4'>
                                                      <input type='checkbox' onChange={(e) => setAgree(e.target.checked)} checked={agree} />
                                                      <div className='ms-2'>Я согласен на обработку <Link className='blue' to={TERMS_ROUTE}>персональных данных</Link></div>
                                                </label>

                                                <div className='d-flex justify-content-sm-center align-items-center mt-3'>
                                                      <button type="submit" disabled={phone.length < 16 || password.length < 4} className='btn-1 fs-13'><span>Войти</span></button>
                                                      <Link to={RESTORE_PASSWORD_ROUTE} className='blue ms-4 fw-5 fs-11'>Забыли пароль?</Link>
                                                </div>

                                                <div className='mt-3 text-sm-center gray fw-5 fs-11'>У вас ещё нет аккаунта? <Link to={REG_ROUTE} className='d-inline link td-under'>Зарегистрироваться</Link></div>
                                          </form>
                                    </Col>
                              </Row>
                        </section>
                  </Container>
            </main>
      )
}

export default Login
