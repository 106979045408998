import { observer } from "mobx-react-lite"
import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { VscArrowLeft } from "react-icons/vsc";
import { Link } from "react-router-dom";
import BonusOperation from '../../components/BonusOperation';
// import NavPagination from '../../components/NavPagination';
import { Context } from "../../index";
// import { PROFILE_ROUTE } from "../../utils/consts";
import QRCode from "react-qr-code";
import { getPoints } from "../../http/userAPI";
import moment from "moment";

const Points = observer(() => {
    const { user } = useContext(Context)
    const [points, setPoints] = useState([])
    const [lastPoint, setLastPoint] = useState(false)

    useEffect(() => {
        getPoints().then(data => {
            if (data && data.points.length > 0) {
                setPoints(data.points)
                setLastPoint(data.points[0])
            }
        })
    }, []);

    return (
        <div className='bonus-page mb-5'>
            <div className='d-flex align-items-center d-lg-none mb-3 mb-sm-4'>
                <Link to="/personal-account" className="btn-4 fs-11 px-2 px-sm-3">
                    <VscArrowLeft className='fs-12' />
                </Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4'>Бонусная карта</h1>
            </div>
            <Row xs={1} sm={2} className="gx-3 gx-md-4 mb-3 mb-md-4">
                <Col>
                    <div className='bonus-card mb-3'>
                        <div className='fw-9 fs-20 mb-3'>{user.user.point ?? 0} Б</div>
                        {user.user.point === 0 && <p className='fs-12'>На вашем счету пока нет бонусных рублей</p>}
                        {lastPoint && <>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                <div>
                                    <div className='fs-11 mb-2 fw-7'>Последняя операция</div>
                                    <div className='fs-10'>{moment(lastPoint.createdAt).fromNow()}</div>
                                </div>
                                <div className='fs-20 fw-7'><span>{lastPoint.type == 'minusOrder' ? '-' : '+'} {lastPoint.point}</span>&nbsp;<span>Б</span></div>
                            </div>
                        </>
                        }
                        <img src="/imgs/logo-top.svg" alt="logo" className='logo' />
                    </div>
                </Col>
                <Col>
                    <div className='bonus-info box-3'>
                        <Row xs={2}>
                            <Col>
                                <QRCode value={user.user.phone} size={140} />
                            </Col>
                            <Col className="d-flex flex-column justify-content-between">
                                <p>Ваш личный QR код для получения скидки в магазине</p>
                                <Link to="/" className='btn-1 fw-4 fs-11 py-2 px-3 mt-2 '><span>Подробнее</span></Link>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className='bonus-tabs box-3 mb-3 mb-md-4'>
                <button type='button' className='active'>Все <span className='d-none d-sm-inline'>события</span></button>
                {/* <button type='button'>Начисления</button>
                <button type='button'>Списания</button> */}
            </div>
            {
                points.length === 0 &&
                <div className='box-3 py-5'>
                    <h5 className='fs-20 text-center mb-4'>Нет операций</h5>
                    <p className='fs-13 text-center mb-4'>Вы получаете бонусные рубли <br />за покупки и можете их <br />использовать при оплате</p>
                    <Link to='/catalog' className='btn-1 mx-auto fs-13'><span>Перейти в каталог</span></Link>
                </div>
            }
            <div className='box-3 pb-sm-5'>
                {
                    points.length > 0 && points.map(item => <BonusOperation item={item} />)
                }
                {/* <NavPagination /> */}
            </div>
        </div>)
})

export default Points;
