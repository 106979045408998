import { $authHost, $host } from "./index";

export const setOrder = async (array) => {
    const { data } = await $host.post('/order/create', array)
    return data
}
export const getOrder = async (id) => {
    const { data } = await $authHost.post('/order/getOrder', { id })
    return data
}
export const getStreets = async (array) => {
    const { data } = await $host.get('/order/getStreets')
    return data
}
export const getOrders = async (page) => {
    const { data } = await $authHost.post('/order/getOrders', { page })
    return data
}
export const deleteOrder = async (id) => {
    const { data } = await $authHost.post('/user/deleteorder', { id })
    return data
}