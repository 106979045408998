import { observer } from "mobx-react-lite";
import React, { useContext, useState } from 'react'
import { GrFormNext } from "react-icons/gr"
import { NavLink, useNavigate } from 'react-router-dom'
import CustomModal from '../../components/CustomModal'
import { Context } from "../../index"
import { LOGIN_ROUTE, PROFILE_ROUTE } from '../../utils/consts'

export default observer(function AccountMenu() {
    const { user } = useContext(Context)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem('token')
        user.setUser(false)
        user.setIsAuth(false)
        navigate(LOGIN_ROUTE)
    }
    return (
        <>
            <h2 className='d-lg-none ms-sm-4 mb-4'>Сергей</h2>
            <nav className="box-2 account-nav">
                <ul>
                    <li>
                        <NavLink to={PROFILE_ROUTE} end>
                            <span>Личные данные</span>
                            <GrFormNext />
                        </NavLink>
                    </li>
                    {user.user.type == 2 &&
                        <li>
                            <NavLink to='companies' >
                                <span>Мои компании</span>
                                <GrFormNext />
                            </NavLink>
                        </li>
                    }
                    <li>
                        <NavLink to='orders'>
                            <span>История покупок</span>
                            <GrFormNext />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='addresses'>
                            <span>Адреса доставок</span>
                            <GrFormNext />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='points'>
                            <span>Бонусная карта</span>
                            <GrFormNext />
                        </NavLink>
                    </li>
                    <li>
                        <button type="button" onClick={() => setShow(!show)}>Выйти из профиля</button>
                    </li>
                </ul>
            </nav>
            <CustomModal isShow={show} setIsShow={setShow} size="lg" >
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h5 className="text-center">Вы точно хотите выйти?</h5>
                        <div className="text-center gray-3 mb-4">Не забудьте сохранить данные для входа</div>
                        <div className="d-flex justify-content-center align-items-center">
                            <button type="button" onClick={() => logout()} className="btn btn-1 px-5">Да</button>
                            <button type="button" onClick={() => setShow(!show)} className="gray-1 ms-4">Отмена</button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    )
})