import React, { useEffect, useState, useContext } from 'react'
import { observer } from "mobx-react-lite"
import { IoAdd, IoRemove } from "react-icons/io5";
import { Context } from "../../index"

export default observer(function AddToCart(props) {
    const { cart } = useContext(Context)
    const { product } = props
    const count = cart.getCartProductCount(product)

    const addCart = () => {
        cart.setCart(product)
    }
    const plusCount = () => {
        cart.setCartCountPlus(product)
    }
    const minusCount = () => {
        cart.setCartCountMinus(product)
    }
    const setCount = (count) => {
        if (count < 0 || count > 10000) {
            return false
        }
        cart.setCartCount(product, Number(count))
    }
    return (
        <div className='count'>
            {
                cart.getCartProductCount(product) ?
                    <div className='count-input'>
                        <button type='button' onClick={() => minusCount()}>
                            <IoRemove />
                        </button>
                        <input type="number" placeholder='1' min={1} value={count ?? 1} max={10000} onChange={(e) => setCount(e.target.value)} />
                        <button type='button' onClick={() => plusCount()}>
                            <IoAdd />
                        </button>
                    </div>
                    : <button type='button' className='btn-1 w-100 fs-11' onClick={() => addCart()}><span>В корзину</span></button>
            }
        </div>
    );
});