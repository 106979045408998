import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { VscArrowLeft } from "react-icons/vsc";
import { FiPhone, FiMapPin, FiClock, FiMail } from "react-icons/fi";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

export default function Contacts() {
    return (
        <main>
            <Container id="contacts-page" className='mb-6'>
                <div className='d-flex align-items-center d-md-block mb-2 mb-sm-4 mb-md-0'>
                    <Link to="/" className="btn-4 fs-11 px-3 mb-md-5">
                        <VscArrowLeft className='fs-12' />
                        <span className='ms-2 d-none d-md-inline'>Назад на главную</span>
                    </Link>
                    <h1 className='inner mb-0 mb-md-5 ms-2 ms-sm-4 ms-md-0'>Контакты</h1>
                </div>
                <Row className='flex-lg-row-reverse'>
                    <Col xl={4}>
                        <Row xs={1} md={2} xl={1}>
                            <Col>
                                <div className='box'>
                                    <h5 className='fs-15'>Отзывы о магазине</h5>
                                    <p className='fs-13'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet libero facilisi dictum ac lectus aliquam, aliquet.</p>
                                    <p className='fs-13 mt-3'>Quam sit amet turpis volutpat elementum.</p>
                                    <button type='button' className='btn-1 fs-11 mt-3 mt-sm-4'><span>Оставить отзыв</span></button>
                                </div>
                            </Col>
                            <Col>
                                <div className='box mt-xl-4'>
                                    <h5 className='fs-15'>Реквизиты компании</h5>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                    <p className='fs-13 mt-2'><span className='fw-7'>Lorem:</span> ipsum dolor sit amet</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={8} className="mt-3 mt-sm-4 mt-lg-5 mt-xl-0">
                        <div className='box shop'>
                            <div className='shop-info'>
                                <h5 className='fs-15'>ул. Комиссара Габишева</h5>
                                <div className='d-flex mb-3'>
                                    <FiPhone className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <div className='gray mb-1'>Телефон</div>
                                        <div><a href="tel:8(904)666-10-33">8(904)666-10-33</a></div>
                                    </div>
                                </div>
                                <div className='d-flex mb-3'>
                                    <FiMapPin className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <div className='gray mb-1'>Адрес</div>
                                        <div>Казань, комиссара Габишева 20а</div>
                                    </div>
                                </div>
                                <div className='d-flex mb-3'>
                                    <FiClock className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <div className='gray mb-1'>График работы</div>
                                        <div>Ежедневно с 9:00 до 19:00</div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <FiMail className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <a href='mailto:tip-top86@bk.ru'>tip-top86@bk.ru</a>
                                    </div>
                                </div>
                            </div>
                            <div className='map'>
                                <YMaps>
                                    <Map instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }} width="100%" height="100%" defaultState={{ center: [55.748260, 49.239976], zoom: 17 }}>
                                        <Placemark geometry={[55.748260, 49.239976]} />
                                    </Map>
                                </YMaps>
                            </div>
                        </div>
                        <div className='box shop mt-3 mt-sm-3 mt-lg-4'>
                            <div className='shop-info'>
                                <h5 className='fs-15'>ул. Большая красная</h5>
                                <div className='d-flex mb-3'>
                                    <FiPhone className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <div className='gray mb-1'>Телефон</div>
                                        <div><a href="tel:8(953)496-71-88">8(953)496-71-88</a></div>
                                    </div>
                                </div>
                                <div className='d-flex mb-3'>
                                    <FiMapPin className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <div className='gray mb-1'>Адрес</div>
                                        <div>Село Высокая гора, ул. Большая красная, 13</div>
                                    </div>
                                </div>
                                <div className='d-flex mb-3'>
                                    <FiClock className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <div className='gray mb-1'>График работы</div>
                                        <div>Ежедневно с 9:00 до 19:00</div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <FiMail className='fs-15 gray' />
                                    <div className='fs-13 ms-3'>
                                        <a href='mailto:tip-top86@bk.ru'>tip-top86@bk.ru</a>
                                    </div>
                                </div>
                            </div>
                            <div className='map'>
                                <YMaps>
                                    <Map instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }} width="100%" height="100%" defaultState={{ center: [55.905195, 49.301016], zoom: 17 }}>
                                        <Placemark geometry={[55.905195, 49.301016]} />
                                    </Map>
                                </YMaps>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    );
}