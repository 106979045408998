import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { VscArrowLeft } from "react-icons/vsc";
import InputMask from 'react-input-mask';
import { NotificationManager } from 'react-notifications';
import { Link } from "react-router-dom";
import { edit } from "../../http/userAPI";
import { Context } from "../../index";

const Edit = observer(() => {
    const { user } = useContext(Context)
    const [userArray, setUserArray] = useState(user.user ?? {
        firstName: '',
        lastName: '',
        patronymic: '',
        phone: '',
        email: '',
        birthday: '',
        sex: 1
    })

    useEffect(() => {
        if (user.isAuth && user.user) {
            setUserArray(user.user)
        }
    }, [user])

    const submit = async (e) => {
        try {
            e.preventDefault();
            let data;
            data = await edit(userArray)
            if (data && data.status) {
                NotificationManager.success('Данные успешно сохранены')
                user.setUser(userArray)
            }
        } catch (e) {
            if (e.response && e.response.data) {
                NotificationManager.error(e.response.data.message)
            } else {
                NotificationManager.error(e)
            }
        }
    }
    const change = (e) => {
        setUserArray({ ...userArray, [e.target.name]: e.target.value })
    }
    return <Row>
        <Col xl={7}>
            <div className='box-2 p-lg-4'>
                <div className='d-flex align-items-center d-lg-none mb-3 mb-sm-4'>
                    <Link to="/personal-account" className="btn-4 fs-11 px-2 px-sm-3">
                        <VscArrowLeft className='fs-12' />
                    </Link>
                    <h1 className='inner mb-0 ms-2 ms-sm-4'>Личные данные</h1>
                </div>
                <form onSubmit={submit} onChange={change} >
                    <h5 className="mb-3">Редактировать профиль</h5>
                    <div className='mb-3 mb-sm-4'>
                        <input type="text" placeholder="Имя" name="firstName" value={userArray.firstName} className="mb-3" />
                        <input type="text" placeholder="Фамилия" name="lastName" value={userArray.lastName} className="mb-3" />
                        <input type="text" placeholder="Отчество" name="patronymic" value={userArray.patronymic} className="mb-3" />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label htmlFor='phone' className='fs-11 fw-6 mb-2'>Номер телефона</label>
                        <InputMask mask="+7 999 999 99 99" placeholder="+7 000 000 00 00" name="phone" maskChar="" value={userArray.phone} disabled={userArray.phone} className="mb-3" />
                        {/* <div className='fs-09 fw-5 mt-2'>Код подтверждения будет выслан Вам на новый номер телефона</div>
                        <button type='button' className='btn-1 fs-09 mt-2'><span>Получить код</span></button> */}
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label htmlFor='email' className='fs-11 fw-6 mb-2'>Email</label>
                        <input type="email" placeholder="E-mail" name="email" value={userArray.email} />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label htmlFor='birthday' className='fs-11 fw-6 mb-2'>Дата рождения</label>
                        <input type='date' name="birthday" id="birthday" value={userArray.birthday} />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label className='fs-11 fw-6 mb-2'>Пол</label>
                        <div className="d-sm-flex">
                            <div className="d-flex align-items-center mt-3 mt-sm-0">
                                <input type="radio" name="sex" value={1} id="male" checked={userArray.sex == 1} />
                                <label for="male" className="ms-2">Мужской</label>
                            </div>
                            <div className="d-flex align-items-center mt-3 mt-sm-0 ms-sm-4">
                                <input type="radio" name="sex" value={2} id="female" checked={userArray.sex == 2} />
                                <label for="female" className="ms-2">Женский</label>
                            </div>
                        </div>
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label className='fs-11 fw-6 mb-2'>Тип профиля</label>
                        <div className="d-sm-flex">
                            <div className="d-flex align-items-center mt-3 mt-sm-0">
                                <input type="radio" name="type" value={1} id="type-1" checked={!userArray.type || userArray.type == 1} />
                                <label for="type-1" className="ms-2">Физ. лицо</label>
                            </div>
                            <div className="d-flex align-items-center mt-3 mt-sm-0 ms-sm-4">
                                <input type="radio" name="type" value={2} id="type-2" checked={userArray.type == 2} />
                                <label for="type-2" className="ms-2">Юр. лицо</label>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className='btn btn-1 fs-11'>
                        <span>Сохранить изменения</span>
                    </button>
                </form>
            </div>
        </Col>
        <Col className='d-none d-xl-block' xl={5}>
            <aside className='mb-4'></aside>
            <aside className='mb-4'></aside>
        </Col>
    </Row>
});
export default Edit