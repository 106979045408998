import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import Slider from 'rc-slider'
import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { VscChevronDown, VscChevronUp } from "react-icons/vsc"
import InputMask from 'react-input-mask'
import { NotificationManager } from 'react-notifications'
import { Link } from "react-router-dom"
import CustomModal from "../components/CustomModal"
import Breadcrumbs from '../components/utils/Breadcrumbs'
import useIsMobile from '../hooks/isMobile'
import { customPrice, paymentText } from "../hooks/useGlobalHook"
import { getStreets, setOrder } from "../http/orderAPI"
import { Context } from "../index"
import { REG_ROUTE } from "../utils/consts"

const Checkout = observer(() => {

    var { user, cart } = useContext(Context)
    const total = toJS(cart.total)
    const totalNoSale = toJS(cart.totalNoSale)
    cart = toJS(cart.cart)
    user = toJS(user.user)

    const [sendOrder, setSendOrder] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)
    const [streets, setStreets] = useState(false)
    const [full, setFull] = useState(true);
    const [succ, setSucc] = useState(false);
    const { mobile } = useIsMobile();
    const [openSale, setOpenSale] = useState(false)
    const [openPoint, setOpenPoint] = useState(false)
    const [authModal, setAuthModal] = useState(false)
    const [checkout, setCheckout] = useState({
        type: 1,
        userId: user.isAuth && user.user ? user.user.id : 0,
        firstName: user.isAuth && user.user.firstName ? user.user.firstName : '',
        lastName: user.isAuth && user.user.lastName ? user.user.lastName : '',
        companyId: user.isAuth && user.user.companyName ? user.user.companyName : '',
        phone: user.isAuth && user.user.phone ? user.user.phone : '',
        email: user.isAuth && user.user.email ? user.user.email : '',
        point: 0,
        payment: 'card',
        delivery: 1,
        total: total,
        products: cart.cart,
        street: '',
        home: '',
        entrance: '',
        code: '',
        floor: '',
        apartment: '',
        comment: '',
        promoId: cart.promo ?? 0
    })

    useEffect(() => {
        if (mobile === true) {
            setFull(false);
        } else { setFull(true); }
    }, [mobile]);

    useEffect(() => {
        document.title = "Оформление заказа"
        getStreets().then(data => {
            setStreets(data);
        });
    }, [])

    const updateTotal = () => {
        if ((total < cart.deliveryMinPrice || total < cart.deliveryMinDelivery) && checkout.delivery == 2) {
            checkout.total = total + cart.deliveryPrice
        } else {
            checkout.total = total
        }
        setCheckout({ ...checkout, total: checkout.total })
    }

    useEffect(() => {
        updateTotal()
    }, [total])

    useEffect(() => {
        setCheckout({ ...checkout, payment: 'card', delivery: 1 })
    }, [checkout.type])

    useEffect(() => {
        setCheckout({ ...checkout, payment: 'card' })
        updateTotal()
    }, [checkout.delivery])

    const submit = async (e) => {
        e.preventDefault()
        setSendLoading(true)

        if (checkout.delivery == 2) {
            if (checkout.street.length < 1) {
                NotificationManager.error('Заполинте поле Улица')
                return
            }
            if (checkout.home.length < 1) {
                NotificationManager.error('Заполинте поле Дом')
                return
            }
            if (checkout.apartment.length < 1) {
                NotificationManager.error('Заполинте поле Квартира')
                return
            }
        }

        let data = await setOrder(checkout)

        if (data && data.formUrl) {
            setSendOrder({ status: 2, link: data.formUrl })
            cart.removeAllCart()
            setCheckout([])
            window.location = data.formUrl
        } else if (data && data.status) {
            setSendOrder({ status: 1 })
            // cart.removeAllCart()
        } else {
            NotificationManager.error(data.message ? data.message : 'Произошла неизвестная ошибка, повторите попытку позже')
        }
        setSendLoading(false)
    }

    const change = (e, name, value) => {
        setCheckout({ ...checkout, [name ?? e.target.name]: value ?? e.target.value })
    }

    return (
        <main className='total-padding'>
            <Container>
                <Breadcrumbs />
                {
                    (succ)
                        ? <section className='py-5 mb-6'>
                            <h1 className='inner text-center'>Спасибо за заказ!</h1>
                            <p className='fs-13 text-center'>Ваш заказ был принят</p>
                            <p className='fs-13 text-center mt-3'>Номер заказа: <span className='fw-8 main-color'>123123</span></p>
                            <Link to='/catalog' className='btn-1 fs-11 mx-auto mt-4 mt-sm-5'>
                                <span>Вернуться в каталог</span>
                            </Link>
                        </section>
                        : <section id="checkout" className='mb-6'>
                            <h1 className='inner mb-2 mb-sm-4 mb-md-5'>Оформление заказа</h1>
                            {/* <p className='fs-13 mb-4 mb-sm-5'>Заполните данные для оформления заказа</p> */}
                            <form onSubmit={submit} onChange={change} >
                                <Row className='justify-content-between'>
                                    <Col lg={7} xxl={6}>
                                        <div className="d-flex flex-row  mb-4">
                                            <label className='fs-13 fw-5 me-4'>
                                                <input type='radio' name='type' value={1} defaultChecked />
                                                <span className='ms-3'>Физ. лицо</span>
                                            </label>
                                            <label className='fs-13 fw-5' onClick={() => !user.isAuth && setAuthModal(!authModal)}>
                                                <input type='radio' name='type' value={2} disabled={!user.isAuth} />
                                                <span className='ms-3'>Юр. лицо</span>
                                            </label>
                                        </div>
                                        <Row md={2} className='gx-2 gx-sm-4'>
                                            <Col>
                                                <input type='text' name="firstName" value={checkout.firstName} placeholder='Введите имя' className='fs-11 mb-4' required />
                                            </Col>
                                            <Col>
                                                <input type='text' name="lastName" value={checkout.lastName} placeholder='Введите фамилию' className='fs-11 mb-4' />
                                            </Col>
                                            <Col>
                                                <InputMask mask="+7 999 999 99 99" placeholder="+7 000 000 00 00" name="phone" maskChar="" value={checkout.phone} className='fs-11 mb-4' required />
                                            </Col>
                                            <Col>
                                                <input type='email' value={checkout.email} name="email" placeholder='Введите Email' className='fs-11 mb-4' required={checkout.type == 2} />
                                            </Col>
                                        </Row>
                                        {
                                            checkout.type == 2 && <>
                                                <input type='text' name="companyName" value={checkout.companyName} placeholder='Полное название компании' className='fs-11 mb-4' required disabled />
                                                <Row md={2} className='gx-2 gx-sm-4'>
                                                    <Col>
                                                        <input type='text' name="companyInn" value={checkout.companyInn} placeholder='ИНН' className='fs-11 mb-4' required disabled />
                                                    </Col>
                                                    <Col>
                                                        <input type='text' name="companyOgrn" value={checkout.companyOgrn} placeholder='ОГРН(ИП)' className='fs-11 mb-4' required disabled />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {
                                            checkout.delivery == 2 && <>
                                                {/* <label className='d-block fs-13 fw-5 mb-2'>Город</label>
                                                <input type='text' name="city" value={checkout.city} placeholder='Город' className='fs-11 mb-4' /> */}

                                                <input type='text' name="street" value={checkout.street} placeholder='Введите улицу' className='fs-11 mb-4' />

                                                <Row md={4} className='gx-2 gx-sm-4'>
                                                    <Col>
                                                        <input type='number' name="home" value={checkout.home} placeholder='Дом' className='fs-11 mb-4' />
                                                    </Col>
                                                    <Col>
                                                        <input type='number' name="entrance" value={checkout.entrance} placeholder='Подъезд' className='fs-11 mb-4' />
                                                    </Col>
                                                    <Col>
                                                        <input type='number' name="apartment" value={checkout.apartment} placeholder='Квартира' className='fs-11 mb-4' />
                                                    </Col>
                                                    <Col>
                                                        <input type='number' name="floor" value={checkout.floor} placeholder='Этаж' className='fs-11 mb-4' />
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                        <textarea name="comment" value={checkout.comment} placeholder='Ваш комментарий' className='fs-11 mb-4' rows={5}></textarea>
                                    </Col>
                                    <Col lg={5}>
                                        <div className='d-block fs-13 fw-5 mb-2'>Способ доставки</div>
                                        <div className='box-2 p-3 p-sm-4 mb-4 mb-xl-5'>
                                            <label className='fs-13'>
                                                <input type='radio' name='delivery' value={1} checked={checkout.delivery == 1} />
                                                <span className='ms-3'>Самовывоз</span>
                                            </label>
                                            <hr />
                                            <label className='fs-13'>
                                                <input type='radio' name='delivery' value={2} checked={checkout.delivery == 2} />
                                                <span className='ms-3'>Доставка</span>
                                            </label>
                                        </div>

                                        <div className='d-block fs-13 fw-5 mb-2'>Способ оплаты</div>
                                        <div className='box-2 p-3 p-sm-4 mb-4 mb-xl-5'>
                                            {
                                                checkout.delivery == 1 ? <>
                                                    <label className='fs-13'>
                                                        <input type='radio' name='payment' value="card" checked={checkout.payment == 'card'} />
                                                        <span className='ms-3'>{paymentText('card')}</span>
                                                    </label>
                                                    <hr />
                                                    <label className='fs-13'>
                                                        <input type='radio' name='payment' value="cash" checked={checkout.payment == 'cash'} />
                                                        <span className='ms-3'>{paymentText('cash')}</span>
                                                    </label>
                                                    <hr />
                                                    <label className='fs-13'>
                                                        <input type='radio' name='payment' value="online" checked={checkout.payment == 'online'} />
                                                        <span className='ms-3'>{paymentText('online')}</span>
                                                    </label>
                                                </>
                                                    :
                                                    <label className='fs-13'>
                                                        <input type='radio' name='payment' value="online" defaultChecked />
                                                        <span className='ms-3'>{paymentText('online')}</span>
                                                    </label>
                                            }
                                            {checkout.type == 2 && <>
                                                <hr />
                                                <label className='fs-13'>
                                                    <input type='radio' name='payment' value="ip" checked={checkout.payment == 'ip'} />
                                                    <span className='ms-3'>{paymentText('ip')}</span>
                                                </label>
                                            </>
                                            }
                                        </div>
                                        {user.isAuth && user.user.point > 0 && <>
                                            <div className='d-block fs-13 fw-5 mb-2'>Оплата бонусами</div>
                                            <div className='box-2 p-3 p-sm-4 mb-4 mb-xl-5'>
                                                <p className='fs-13 mb-3'>Доступно {customPrice(user.user.point)} Бонусов</p>
                                                <Slider min={0} value={checkout.point} max={user.user.point} name="point" onChange={(value) => change(false, 'point', value)} />
                                                <div className='d-flex align-items-center mt-3 fs-13 fw-7'>
                                                    <span>Будет списано</span>
                                                    <input type='number' name="point" className='fw-7 mx-2 mx-xl-3 rounded-10 w-min p-2 text-center' placeholder='0' min={0} value={checkout.point} max={user.user.point} />
                                                    <span>Бонусов</span>
                                                </div>
                                            </div>
                                        </>
                                        }

                                        <div className='total'>
                                            <div className="d-block">
                                                <h5 onClick={() => setFull((full === true) ? false : true)} className='order-1 d-flex align-items-center mb-2 mb-md-3 mb-lg-4'>
                                                    <span className='me-4'>Корзина</span>
                                                    {
                                                        (full === true)
                                                            ? <VscChevronUp className='d-lg-none fs-12' />
                                                            : <VscChevronDown className='d-lg-none fs-12' />
                                                    }
                                                </h5>
                                            </div>
                                            {
                                                (mobile === false || full === true) &&
                                                <div className="d-block">
                                                    <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-row justify-content-between pb-3'>
                                                            <div>Товары</div>
                                                            <div>{customPrice(totalNoSale)} ₽</div>
                                                        </div>
                                                        {
                                                            cart.totalSale > 0 && <>
                                                                <div className='cursor-pointer d-flex flex-row justify-content-between pb-3' onClick={() => setOpenSale(!openSale)} aria-expanded={openSale}>
                                                                    <div>Скидка</div>
                                                                    <div className="d-flex align-items-center">{customPrice(cart.totalSale)} ₽
                                                                        {
                                                                            openSale ?
                                                                                <VscChevronUp color="#999" className='point-icon fs-12' />
                                                                                :
                                                                                <VscChevronDown color="#999" className='point-icon fs-12' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Collapse in={openSale}>
                                                                    <div>
                                                                        {
                                                                            cart.products && cart.products.map(e => e.priceSale > 0 ? <div className='point-product d-flex flex-row justify-content-between pb-3'>
                                                                                <div className="gray small">{e.title}</div>
                                                                                <div className="gray small">- {customPrice((e.priceSale * e.count) - (e.price * e.count))} ₽</div>
                                                                            </div> : null)
                                                                        }
                                                                    </div>
                                                                </Collapse>
                                                            </>
                                                        }
                                                        {
                                                            cart.point > 0 && <>
                                                                <div className='cursor-pointer d-flex flex-row justify-content-between pb-3' onClick={() => setOpenPoint(!openPoint)} aria-expanded={openPoint}>
                                                                    <div>Начисление баллов</div>
                                                                    <div className="d-flex align-items-center">{cart.point} Б
                                                                        {
                                                                            openPoint ?
                                                                                <VscChevronUp color="#999" className='point-icon fs-12' />
                                                                                :
                                                                                <VscChevronDown color="#999" className='point-icon fs-12' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Collapse in={openPoint}>
                                                                    <div>
                                                                        {
                                                                            cart.products && cart.products.map(e => e.pointProcent > 0 ? <div className='point-product d-flex flex-row justify-content-between pb-3'>
                                                                                <div className="gray small">{e.title}</div>
                                                                                <div className="gray small">+ {customPrice((e.price * e.count) * e.pointProcent / 100)} Б</div>
                                                                            </div> : null)
                                                                        }
                                                                    </div>
                                                                </Collapse>
                                                            </>
                                                        }
                                                    </div>
                                                    <hr className='order-2 order-lg-3' />
                                                </div>
                                            }
                                            <div className='order-1 order-lg-4 fw-5 fs-13 d-flex align-items-center justify-content-between py-lg-2'>
                                                <div>Итого</div>
                                                <div>{customPrice(total)} ₽</div>
                                            </div>
                                            <button type="submit" className='order-4 btn-1 fs-11 w-100 px-4 mt-2'><span>Оформить заказ на {customPrice(total)} ₽</span></button>
                                        </div>
                                    </Col>
                                </Row>
                                <Link to='/cart' className='btn-3 fs-11 mt-4'>Назад в корзину</Link>
                            </form>
                        </section>
                }
            </Container>
            <CustomModal isShow={authModal} setIsShow={setAuthModal} title="Доступ закрыт">
                <p className="mb-3">Для оформления заказа от Юр. лица необходимо пройти регистрацию на сайте</p>
                <Link to={REG_ROUTE} className='order-4 btn-1 fs-11 w-100 px-4 mt-2'><span>Перейти к регистрации</span></Link>
            </CustomModal>
        </main>
    )
})

export default Checkout
