import React from 'react';
import { Link } from 'react-router-dom';
import { CATALOG_ROUTE } from "../utils/consts";

export default function CategoryCard(props) {
    const { item } = props
    const category = item.category ?? false
    const sub = item.subCategories ?? false

    return (
        <div className='category-card'>
            
            <ul className='list-unstyled'>
                <li><h3><Link to={CATALOG_ROUTE + '/' + category.id}>{category.title ?? 'Категория'}</Link></h3></li>
                {
                    sub && sub.map(e => <li><Link to={CATALOG_ROUTE + '/' + e.id}>{e.title ?? 'Категория'}</Link></li>)
                }
            </ul>
            <Link to={CATALOG_ROUTE + '/' + category.id} className='more'>Смотреть все</Link>
        </div>
    );
}