import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FiBookmark, FiChevronRight, FiMenu, FiNavigation } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { Context } from "../index";
import { ABOUT_ROUTE, LOGIN_ROUTE, CART_ROUTE, CATALOG_ROUTE, CONTACTS_ROUTE, DELIVERY_ROUTE, FAVORITES_ROUTE, HOME_ROUTE, PROFILE_ROUTE, ARTICLES_ROUTE } from "../utils/consts";
import { SEARCH_ROUTE } from './../utils/consts';

const Header = observer(() => {
    const { favorite, cart, user } = useContext(Context)
    // const localCities = localStorage.getItem('cities')
    const [catalogMenu, setCatalogMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);

    const meHandler = () => {
        setCatalogMenu(true);
    }
    const mlHandler = () => {
        setCatalogMenu(false);
    }

    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setMobileMenu(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    })

    return (
        <>
            <header>
                <div className='top'>
                    <Container className="h-100">
                        <div className='h-100 d-none d-sm-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <button type="button" className='d-flex align-items-center'>
                                    <FiNavigation />
                                    <span className='ms-2'>Казань</span>
                                </button>
                                <Link to={DELIVERY_ROUTE} className='ms-4'>Доставка</Link>
                                <Link to={CONTACTS_ROUTE} className='ms-4'>Контакты</Link>
                            </div>
                            <div>
                                <a className="fs-13 fw-6" href="tel:8(843)266-61-39">8(843)266-61-39</a>
                            </div>
                        </div>
                    </Container>
                </div>
                <nav>
                    <Container>
                        <ul className='list-unstyled'>
                            {/* onMouseEnter={() => meHandler()} onMouseLeave={() => mlHandler()} */}
                            <li><NavLink to={CATALOG_ROUTE}><span>Каталог</span></NavLink></li>
                            <li><NavLink to={SEARCH_ROUTE}><span>Поиск</span></NavLink></li>
                            <li><NavLink to={ABOUT_ROUTE}><span>О нас</span></NavLink></li>
                        </ul>
                        <Link to={HOME_ROUTE} className='logo'>
                            <img src="/imgs/logo-top.svg" alt='Тип-топ' />
                        </Link>
                        <button type='button' className='menu' onClick={() => setMobileMenu((mobileMenu === true) ? false : true)}><FiMenu /></button>
                        <ul className='list-unstyled'>
                            <li><NavLink to={FAVORITES_ROUTE}><span>Избранное</span>{favorite.products.length > 0 ? <span className="header-count">{favorite.products.length}</span> : null}</NavLink></li>
                            {
                                user.isAuth ?
                                    <li><NavLink to={PROFILE_ROUTE}><span>Профиль</span></NavLink></li>
                                    :
                                    <li><NavLink to={LOGIN_ROUTE}><span>Войти</span></NavLink></li>
                            }
                            <li><NavLink to={CART_ROUTE}><span>Корзина</span>{cart.products.length > 0 ? <span className="header-count">{cart.products.length}</span> : null}</NavLink></li>
                        </ul>
                    </Container>
                </nav>
            </header>
            {
                (mobileMenu) &&
                <div id='mobileMenu'>
                    <Container>
                        <section ref={ref} className='menu'>
                            {/* <div className='p-3 p-sm-4 fs-13 d-flex align-items-center'>
                                <button type='button' className='btn-1 px-2 px-sm-3 py-1 py-sm-2 fw-4'><span>Новинки</span></button>
                                <button type='button' className='btn-1 px-2 px-sm-3 py-1 py-sm-2 color-2 fw-4 ms-3 ms-sm-4'><span>Хиты</span></button>
                                <button type='button' className='btn-1 px-2 px-sm-3 py-1 py-sm-2 color-3 fw-4 ms-3 ms-sm-4'><span>Распродажа</span></button>
                            </div>
                            <hr className='m-0' /> */}
                            <nav className='p-3 p-sm-4'>
                                <ul className='list-unstyled'>
                                    <li>
                                        <Link to={CATALOG_ROUTE}>
                                            <span>Каталог</span>
                                            <FiChevronRight className='chevron' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={FAVORITES_ROUTE}>
                                            <span>Избранное</span>
                                            <FiChevronRight className='chevron' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={ABOUT_ROUTE}>
                                            <span>О нас</span>
                                            <FiChevronRight className='chevron' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={ARTICLES_ROUTE}>
                                            <span>Статьи</span>
                                            <FiChevronRight className='chevron' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={DELIVERY_ROUTE}>
                                            <span>Доставка и оплата</span>
                                            <FiChevronRight className='chevron' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={CONTACTS_ROUTE}>
                                            <span>Контакты</span>
                                            <FiChevronRight className='chevron' />
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            <hr className='m-0' />
                            <div className='p-3 p-sm-4'>
                                <a href="tel:8(843)266-61-39" className='btn btn-1 fs-12 w-100 mt-2 mt-sm-2 mb-2'><span>8(843)266-61-39</span></a>
                                <div className='fw-5 fs-09 text-center'>Режим работы: с 8 до 19</div>
                            </div>
                        </section>
                    </Container>
                </div>
            }
            {
                (catalogMenu) &&
                <div id="catalogMenu" onMouseEnter={() => meHandler()}>
                    <Container onMouseLeave={() => mlHandler()}>
                        <div className="level-1">
                            <h5>Каталог</h5>
                            <ul>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Стройматериалы</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category' className='active'>
                                        <FiBookmark className='icon' />
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <FiBookmark className='icon' />
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="level-2">
                            <ul>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Стройматериалы</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category' className='active'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="level-3">
                            <ul>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Стройматериалы</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category' className='active'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/catalog/category'>
                                        <span>Первый уровень 1</span>
                                        <FiChevronRight className='chevron' />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="level-4">
                            <Row xs={2} className="g-3 g-xl-4">
                                <Col>
                                    <Link to='/catalog/category' className='brand'>
                                        <img src="/imgs/img4.png" alt='Название бренда' />
                                        <h6>Название бренда</h6>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to='/catalog/category' className='brand'>
                                        <img src="/imgs/img4.png" alt='Название бренда' />
                                        <h6>Название бренда</h6>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to='/catalog/category' className='brand'>
                                        <img src="/imgs/img4.png" alt='Название бренда' />
                                        <h6>Название бренда</h6>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to='/catalog/category' className='brand'>
                                        <img src="/imgs/img4.png" alt='Название бренда' />
                                        <h6>Название бренда</h6>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            }
        </>
    );
})

export default Header;