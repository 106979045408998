import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import UserStore from "./store/UserStore";
import ProductStore from "./store/ProductStore";
import CartStore from "./store/CartStore";
import FavoriteStore from "./store/FavoriteStore";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-slider/assets/index.css';
import './styles/style.css';
import './styles/fonts/stylesheet.css';
import moment from 'moment';
import 'moment/locale/ru'
moment.locale('ru');

export const Context = createContext(null)
const root = createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{
        user: new UserStore(),
        cart: new CartStore(),
        favorite: new FavoriteStore(),
        product: new ProductStore(),
    }}>
        <App />
    </Context.Provider>
);