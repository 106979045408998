import { Link } from 'react-router-dom';
import BtnFav from '../components/utils/BtnFav';
import { customPrice } from "../hooks/useGlobalHook";
import { PRODUCT_ROUTE } from './../utils/consts';

export default function OrderProductItem(props) {
      const { item } = props
      if (!item) {
            return null
      }
      const imagesParse = item.images && typeof (item.images) === 'string' ? JSON.parse(item.images) : item.images
      const image = imagesParse ? imagesParse[0] : false
      return (<div className='cart-item position-relative' >
            <Link to={PRODUCT_ROUTE + '/' + item.id}>
                  <img src={image ? process.env.REACT_APP_API_URL + 'products/' + image : '/imgs/img2.png'} alt={item.title} />
            </Link>
            <div className='info'>
                  <div className='order-1 flex-1 pe-3'>
                        <div className='top-left'>
                              {
                                    (item.saleProcent > 0) &&
                                    <div className='discount'>-{item.saleProcent}%</div>
                              }
                              {
                                    (item.pointProcent > 0) &&
                                    <div className='bonus'>{item.pointProcent}% бонусов</div>
                              }
                        </div>
                        {item.code > 0 && <div className='fw-6 mb-1 mb-md-2 mb-xl-3'><span className='light-gray'>Код:</span> {item.code}</div>}
                        <h5 className='mb-1 mb-md-2'>{item.title} <span className='gray fw-4 fs-07'>({item.count} шт)</span></h5>
                        <div className='fs-11 gray maxtext'>{item.description}</div>
                  </div>
                  <div className='order-2 order-md-3 price'>
                        <div>{customPrice(item.price * item.count ?? 0)} ₽</div>
                        {item.priceSale ? <div><del className="gray sale-price mb-2">{customPrice(item.priceSale * item.count) ?? 0} ₽</del></div> : null}
                        {item.count > 1 && <div><small className="gray sale-price">1шт {customPrice(item.price)} ₽</small></div>}
                  </div>
            </div>
            <div className='btns'>
                  <BtnFav data={item} />
            </div>
      </div>
      );
}