import { makeAutoObservable } from "mobx";
import { getCartProducts } from "../http/cartAPI"
import moment from "moment"

const sortLocal = localStorage.getItem('sortFavorite')

export default class FavoriteStore {
    constructor() {
        this._products = []
        this._favorite = []
        makeAutoObservable(this)
        this.getData()
    }
    getData() {
        let favorite = localStorage.getItem('favorite');
        if (favorite) {
            this._favorite = JSON.parse(favorite);

            getCartProducts(this._favorite)
                .then(data => {
                    if (data.status) {
                        let newData = data.products.map(item => {
                            this._favorite.map(e => {
                                if (item.id == e) {
                                    item.count = 1
                                    item.saleProcent = item.priceSale ? parseInt((parseInt(item.priceSale) - parseInt(item.price)) / ((parseInt(item.priceSale) + parseInt(item.price)) / 2) * 100) : 0
                                }
                                return e
                            })
                            return item
                        })
                        this.sortProducts(sortLocal, newData)
                    }
                })
                .catch(e => console.log(e))
        }
    }
    sortProducts(sort, products) {
        if (sort) {
            if (sort == 0) {
                products.sort(function (a, b) {
                    return a.id - b.id;
                });
            } else if (sort == 1) {
                products.sort(function (a, b) {
                    return (a.price * a.count) - (b.price * b.count);
                });
            } else if (sort == 2) {
                products.sort(function (a, b) {
                    return (b.price * b.count) - (a.price * a.count);
                });
            } else if (sort == 3) {
                products.sort(function (a, b) {
                    return new Date(moment(a.createdAt).format("YYYY-MM-DD")) - new Date(moment(b.createdAt).format("YYYY-MM-DD"));
                });
            }
            localStorage.setItem('sortFavorite', sort)
        }
        this._products = products
    }
    setFavorite(product) {
        if (!product || 0 === product.length) {
            return false
        }
        let id_yes = this._favorite.find(id => id === product.id);
        if (id_yes && id_yes != undefined) {
            this._favorite = this._favorite.filter(id => id !== product.id)
        } else {
            this._favorite.push(product.id)
        }

        localStorage.setItem('favorite', JSON.stringify(this._favorite))
        this.getData()
    }
    checkFavorite(product) {
        let id_yes = product && product.id ? this._favorite.find(id => id === product.id) : false;
        return { status: id_yes ? true : false }
    }

    removeAllFavorite() {
        this._products = []
        this._favorite = []
        localStorage.removeItem('favorite')
    }
    setProducts(products) {
        this._products = products
    }
    get favorite() {
        return this._favorite
    }
    get products() {
        return this._products
    }
}
