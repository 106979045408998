import React from 'react';
import Modal from "react-bootstrap/Modal";
import { IoCloseOutline } from 'react-icons/io5';

export default function CustomModal(props) {
      const { isShow, children, title, setIsShow, size = 'lg' } = props
      const handleClose = () => setIsShow(false)

      return (
            <Modal
                  className="custom-modal"
                  show={isShow}
                  onHide={handleClose}
                  backdrop={true}
                  data-bs-backdrop={false}
                  centered={true}
                  size={size}
            >
                  <Modal.Header>
                        <div>
                              {title ? <h3>{title}</h3> : null}
                        </div>
                        <button
                              type="button"
                              className="btn-close"
                              onClick={handleClose}
                        />
                  </Modal.Header>
                  <Modal.Body>
                        {children}
                  </Modal.Body>
            </Modal>
      );
};

