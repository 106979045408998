import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'

SwiperCore.use([Navigation, Pagination])

export default function About() {

    useEffect(() => {
        document.title = "О нас"
    }, [])

    return (
        <main className='pt-5'>
            <section className="position-relative container mb-8 mt-5">
                <div className='row row-cols-lg-2 mb-5'>
                    <div>
                        <h1 className="inner text-center text-md-start">Fugiat ipsum ex ex do cupidatat mollit consectetur exercitation ipsum do.</h1>
                        <p className='fs-11 mb-3'>Ex occaecat ea dolore sunt aliqua et culpa. Nulla culpa esse sunt qui do cillum ex reprehenderit nostrud est incididunt consectetur adipisicing. Ad incididunt exercitation culpa Lorem. Qui ex fugiat nisi veniam. Nulla amet aute nostrud aliquip minim.</p>
                        <p className='fs-11'>Consequat cupidatat ex id enim quis consectetur officia aliqua reprehenderit consequat in excepteur sint. Lorem reprehenderit incididunt excepteur in nulla sunt enim sint consequat exercitation nisi proident aliqua. Irure ea magna est eiusmod enim dolor ex sint proident enim amet id mollit. Commodo ipsum pariatur cillum non eiusmod.</p>
                    </div>
                    <div>
                        <Swiper
                            loop={true}
                            slidesPerView={1}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            className="swiper-about"
                        >
                            <SwiperSlide>
                                <img src="/imgs/about/about-3.jpg" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/imgs/about/about-2.jpg" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/imgs/about/about-1.jpg" className="img-fluid" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>
        </main>
    );
};
