import React, { useEffect, useState } from 'react'
import { VscArrowLeft } from "react-icons/vsc"
import { Link, useParams } from "react-router-dom"
import Loading from "../../components/loading/Loading"
import NavPagination from '../../components/NavPagination'
import OrderItem from '../../components/OrderItem'
import { getOrders } from "../../http/orderAPI"
import { CATALOG_ROUTE, PROFILE_ROUTE } from "../../utils/consts"

const Orders = () => {
    const { page } = useParams()
    const [data, setData] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getOrders(page).then(orders => {
            if (orders) {
                setData(orders)
            }
        }).finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Loading full/>
    }

    if (!data || data.orders.length === 0) {
        return <>
            <div className='d-flex align-items-center mb-3 mb-sm-4'>
                <Link to={PROFILE_ROUTE} className="btn-4 fs-11 px-2 px-sm-3">
                    <VscArrowLeft className='fs-12' />
                </Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4'>История покупок</h1>
            </div>
            <div className='box-2 p-5'>
                <h4 className='fw-7 text-center mb-4'>Ваш список заказов пуст</h4>
                <p className='fs-13 text-center mb-4'>Найдите товары в нашем каталоге<br /> или воспользуйтесь поиском</p>
                <Link to={CATALOG_ROUTE} className='btn-1 mx-auto'><span>Перейти в каталог</span></Link>
            </div>
        </>
    }
    return <>
        <div className='d-flex align-items-center mb-3 mb-sm-4'>
            <Link to={PROFILE_ROUTE} className="btn-4 fs-11 px-2 px-sm-3">
                <VscArrowLeft className='fs-12' />
            </Link>
            <h1 className='inner mb-0 ms-2 ms-sm-4'>История покупок</h1>
        </div>
        {data.orders.map(item => <OrderItem item={item} />)}
        <NavPagination pagination={data.pagination} link={PROFILE_ROUTE + '/orders'} />
    </>
}

export default Orders
