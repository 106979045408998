import { makeAutoObservable } from "mobx";
import { getCartProducts } from "../http/cartAPI"
import moment from "moment"

const sortLocal = localStorage.getItem('sort')

export default class CartStore {
    constructor() {
        this._products = []
        this._cart = []
        this._total = 0
        this._totalNoSale = 0
        this._point = 0
        this._deliveryMinDelivery = 1000
        this._deliveryMinPrice = 1000
        this._deliveryPrice = 0
        makeAutoObservable(this)
        this.getData()
    }
    getData() {
        let cart = localStorage.getItem('cart')
        if (cart) {
            this._total = 0
            this._totalNoSale = 0
            this._point = 0
            this._cart = JSON.parse(cart);
            let ids = this._cart.map(e => e.id)

            getCartProducts(ids)
                .then(data => {
                    if (data.status) {
                        let newData = data.products.map(item => {
                            this._cart.map(e => {
                                if (item.id == e.id) {
                                    item.count = e.count
                                    item.saleProcent = item.priceSale ? parseInt((parseInt(item.priceSale) - parseInt(item.price)) / ((parseInt(item.priceSale) + parseInt(item.productPrice.price)) / 2) * 100) : 0
                                    this._total += item.productPrice.price * item.count
                                    if (item.priceSale > 0) {
                                        this._totalNoSale += item.priceSale * item.count
                                    } else {
                                        this._totalNoSale += item.productPrice.price * item.count
                                    }
                                    if (item.pointProcent > 0) {
                                        this._point += parseInt((item.productPrice.price * item.count) * item.pointProcent / 100)
                                    }
                                }
                                return e
                            })
                            return item
                        })
                        this.sortProducts(sortLocal, newData)
                    }
                })
                .catch(e => console.log(e))
        }
    }
    sortProducts(sort, products) {
        if (sort) {
            if (sort == 0) {
                products.sort(function (a, b) {
                    return a.id - b.id;
                });
            } else if (sort == 1) {
                products.sort(function (a, b) {
                    return (a.productPrice.price * a.count) - (b.productPrice.price * b.count);
                });
            } else if (sort == 2) {
                products.sort(function (a, b) {
                    return (b.productPrice.price * b.count) - (a.productPrice.price * a.count);
                });
            } else if (sort == 3) {
                products.sort(function (a, b) {
                    return new Date(moment(a.createdAt).format("YYYY-MM-DD")) - new Date(moment(b.createdAt).format("YYYY-MM-DD"));
                });
            }
            localStorage.setItem('sort', sort)
        }
        this._products = products
    }
    setCart(product) {
        if (!product || 0 === product.length) {
            return false
        }
        let item = {
            id: product.id,
            count: 1,
            price: product.productPrice.price,
            priceSale: product.priceSale,
            pointProcent: product.pointProcent
        }
        let id_yes = Object.keys(this._cart).find(ids => {
            let cart = this._cart[ids]
            return JSON.stringify(cart) === JSON.stringify(item)
        });

        if (id_yes) {
            this._cart[id_yes].count += 1
        } else {
            this._cart.push(item)
        }

        localStorage.setItem('cart', JSON.stringify(this._cart))
        this.getData()
    }
    setIdsCart(products) {
        if (!products || 0 === products.length) {
            return false
        }
        this.removeAllCart()
        let ids = products.map(e => e.id)
        getCartProducts(ids)
            .then(data => {
                if (data.status) {
                    let array = data.products.map(item => {
                        let infoCart = products.find(e => e.id == item.id)
                        let data = {
                            id: item.id,
                            count: infoCart.count,
                            price: item.productPrice.price,
                            priceSale: item.priceSale,
                            pointProcent: item.pointProcent
                        }
                        return data
                    })
                    if (array && array.length > 0) {
                        this._cart = array
                        localStorage.setItem('cart', JSON.stringify(this._cart))
                        this.getData()
                    }
                }
            })
    }
    getCartProductCount(product) {
        if (product) {
            let id_yes = Object.keys(this._cart).find(ids => this._cart[ids].id === product.id)
            if (id_yes) {
                return this._cart[id_yes].count
            }
        }
    }
    setCartCountPlus(product) {
        if (product) {
            let id_yes = Object.keys(this._cart).find(ids => this._cart[ids].id === product.id);
            if (id_yes) {
                this._cart[id_yes].count += 1
                localStorage.setItem('cart', JSON.stringify(this._cart))
                this.getData()
            }
        }
    }
    setCartCountMinus(product) {
        if (product) {
            let id_yes = Object.keys(this._cart).find(ids => this._cart[ids].id === product.id);
            if (id_yes) {
                if (this._cart[id_yes].count > 1) {
                    this._cart[id_yes].count -= 1
                } else if (this._cart.length > 0) {
                    this._cart.splice(id_yes, 1);
                }
                localStorage.setItem('cart', JSON.stringify(this._cart))
                this.getData()
            }
        }
    }
    setCartCount(product, count) {
        if (product && count >= 0) {
            let id_yes = Object.keys(this._cart).find(ids => this._cart[ids].id === product.id);
            if (id_yes) {
                this._cart[id_yes].count = count
                localStorage.setItem('cart', JSON.stringify(this._cart))
                this.getData()
            }
        }
    }
    removeCartProduct(product) {
        if (product) {
            let newCart = this._cart.filter(item => item.id !== product.id);
            if (newCart) {
                localStorage.setItem('cart', JSON.stringify(newCart))
                this.getData()
            }
        }
    }
    removeAllCart() {
        this._products = []
        this._cart = []
        this._total = 0
        this._totalNoSale = 0
        this._point = 0
        localStorage.removeItem('cart')
        this.getData()
    }
    checkCart(product) {
        let id_yes = (product && product.id) ? Object.keys(this._cart).find(ids => this._cart[ids].id === product.id) : false
        if (id_yes) {
            return { status: (id_yes) ? true : false, count: (id_yes && this._cart[id_yes]) ? this._cart[id_yes].count : 1 }
        } else {
            return { status: false, count: 1 }
        }
    }
    setProducts(products) {
        this._products = products
    }
    get cart() {
        return this._cart
    }
    get products() {
        return this._products
    }
    get deliveryMinDelivery() {
        return this._deliveryMinDelivery
    }
    get deliveryMinPrice() {
        return this._deliveryMinPrice
    }
    get deliveryPrice() {
        return this._deliveryPrice
    }
    get point() {
        return this._point
    }
    get total() {
        return this._total
    }
    get totalNoSale() {
        return this._totalNoSale
    }
    get totalSale() {
        return this._totalNoSale - this._total
    }
}
