import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { VscArrowLeft } from "react-icons/vsc";
import { NotificationManager } from 'react-notifications';
import { Link, useNavigate, useParams } from "react-router-dom";
import AddressItem from '../../components/AddressItem';
import Loading from "../../components/loading/Loading";
import NavPagination from '../../components/NavPagination';
import { createAddress, deleteAddress, editAddress, getAddress, getAddresses } from "../../http/userAPI";
import { Context } from "../../index";
import { PROFILE_ROUTE } from '../../utils/consts';

const Addresses = () => {
    const { action, addressId } = useParams()
    const { user } = useContext(Context)
    const [data, setData] = useState(false)
    const [addressCreate, setAddressCreate] = useState(false)
    const [addressEdit, setAddressEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Мои адреса"
        if (user) {
            getAddresses().then(e => e && setData(e)).finally(() => setLoading(false))
        }
    }, [])

    useEffect(() => {
        if (addressId && user) {
            getAddress(addressId).then(e => (e && e.address) && setAddressEdit(e.address)).finally(() => setLoading(false))
        }
    }, [addressId]);

    const deleteClick = async () => {
        try {
            let data = await deleteAddress(addressEdit.id);
            if (data) {
                NotificationManager.success('Адрес успешно удалена');
                navigate(PROFILE_ROUTE + '/addresses')
            }
        } catch (e) {
            if (e.response && e.response.data) {
                NotificationManager.error(e.response.data.message);
            } else {
                NotificationManager.error(e);
            }
        }
    }
    if (loading) {
        return <Loading full/>
    }
    if (action && action == 'create') {

        const change = (e) => {
            if (e.target.type == 'checkbox') {
                setAddressCreate({ ...addressCreate, [e.target.name]: e.target.checked })
            } else {
                setAddressCreate({ ...addressCreate, [e.target.name]: e.target.value })
            }
        }

        const submit = async (e) => {
            try {
                e.preventDefault()
                if (!addressCreate || !addressCreate.street || !addressCreate.home || !addressCreate.entrance || !addressCreate.floor || !addressCreate.apartment) {
                    NotificationManager.error('Заполните обязательные поля')
                    return
                }
                let data = await createAddress(addressCreate);
                if (data) {
                    NotificationManager.success('Адрес успешно добавлена')
                    navigate(PROFILE_ROUTE + '/addresses')
                }
            } catch (e) {
                if (e.response && e.response.data) {
                    NotificationManager.error(e.response.data.message)
                } else {
                    NotificationManager.error(e)
                }
            }
        }

        return (
            <>
                <div className='d-flex align-items-center mb-3 mb-sm-4'>
                    <Link to={PROFILE_ROUTE + '/addresses'} className="btn-4 fs-11 px-2 px-sm-3">
                        <VscArrowLeft className='fs-12' />
                    </Link>
                    <h1 className='inner mb-0 ms-2 ms-sm-4'>Добавить адрес</h1>
                </div>
                <form onSubmit={submit} onChange={change}>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='Название адреса' name="title" value={addressEdit.title} className='fs-11 fw-5' />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='Улица' name="street" value={addressEdit.street} className='fs-11 fw-5' required />
                    </div>
                    <Row md={4} className="gx-2 gx-sm-3 gx-md-4">
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='text' placeholder='Дом' name="home" value={addressEdit.home} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='number' placeholder='Подъезд' name="entrance" value={addressEdit.entrance} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='number' placeholder='Этаж' name="floor" value={addressEdit.floor} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='number' placeholder='Квадртира' name="apartment" value={addressEdit.apartment} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                    </Row>
                    <div className='mb-3 mb-sm-4'>
                        <div className='fs-13 fw-7 mb-2'>Комментарий</div>
                        <textarea rows={5} placeholder='Комментарий' name="comment" value={addressEdit.comment} className='fs-11 fw-5'></textarea>
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label>
                            <input type='checkbox' name="main" checked={addressCreate.main} />
                            <span className='ms-2'>сделать по умолчанию</span>
                        </label>
                    </div>
                    <div className='d-flex'>
                        <button type='submit' className='btn-1 fs-11'>
                            <span>Сохранить</span>
                        </button>
                    </div>
                </form>
            </>
        );
    } else if (action && action == 'edit') {

        const change = (e) => {
            if (e.target.type == 'checkbox') {
                setAddressEdit({ ...addressEdit, [e.target.name]: e.target.checked })
            } else {
                setAddressEdit({ ...addressEdit, [e.target.name]: e.target.value })
            }
        }
        const submit = async (e) => {
            try {
                e.preventDefault()
                if (!addressEdit || !addressEdit.street || !addressEdit.home || !addressEdit.entrance || !addressEdit.floor || !addressEdit.apartment) {
                    NotificationManager.error('Заполните обязательные поля')
                    return
                }
                let data = await editAddress(addressEdit);
                if (data) {
                    NotificationManager.success('Адрес успешно изменена')
                    navigate(PROFILE_ROUTE + '/addresses')
                }
            } catch (e) {
                if (e.response && e.response.data) {
                    NotificationManager.error(e.response.data.message)
                } else {
                    NotificationManager.error(e)
                }
            }
        }

        return (
            <>
                <div className='d-flex align-items-center mb-3 mb-sm-4'>
                    <Link to={PROFILE_ROUTE + '/addresses'} className="btn-4 fs-11 px-2 px-sm-3">
                        <VscArrowLeft className='fs-12' />
                    </Link>
                    <h1 className='inner mb-0 ms-2 ms-sm-4'>Редактирование адреса</h1>
                </div>
                <form onSubmit={submit} onChange={change}>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='Название адреса' name="title" value={addressEdit.title} className='fs-11 fw-5' />
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <input type='text' placeholder='Улица' name="street" value={addressEdit.street} className='fs-11 fw-5' required />
                    </div>
                    <Row md={4} className="gx-2 gx-sm-3 gx-md-4">
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='text' placeholder='Дом' name="home" value={addressEdit.home} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='number' placeholder='Подъезд' name="entrance" value={addressEdit.entrance} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='number' placeholder='Этаж' name="floor" value={addressEdit.floor} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                        <Col>
                            <div className='mb-3 mb-sm-4'>
                                <input type='number' placeholder='Квадртира' name="apartment" value={addressEdit.apartment} className='fs-11 fw-5' required />
                            </div>
                        </Col>
                    </Row>
                    <div className='mb-3 mb-sm-4'>
                        <div className='fs-13 fw-7 mb-2'>Комментарий</div>
                        <textarea rows={5} placeholder='Комментарий' name="comment" value={addressEdit.comment} className='fs-11 fw-5'></textarea>
                    </div>
                    <div className='mb-3 mb-sm-4'>
                        <label>
                            <input type='checkbox' name="main" checked={addressEdit.main} />
                            <span className='ms-2'>сделать по умолчанию</span>
                        </label>
                    </div>
                    <div className='d-flex'>
                        <button type='submit' className='btn-1 fs-11'>
                            <span>Сохранить изменения</span>
                        </button>
                        <button type='button' onClick={deleteClick} className='btn-3 fs-11 ms-4'>
                            <span>Удалить адрес</span>
                        </button>
                    </div>
                </form>
            </>
        );
    } else if (data && data.addresses.length > 0) {
        return <div>
            <div className='d-flex align-items-center mb-3 mb-sm-4'>
                <Link to={PROFILE_ROUTE} className="btn-4 fs-11 px-2 px-sm-3"><VscArrowLeft className='fs-12' /></Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4'>Мои адреса</h1>
            </div>
            <Link to='create' className='btn-1 fs-14 w-100 mb-3'>
                <span>Добавить адрес</span>
            </Link>
            {data.addresses.map((item, index) => <AddressItem item={item} index={index} />)}
            <NavPagination pagination={data.pagination} link='/addresses' />
        </div>
    } else {
        return <>
            <div className='d-flex align-items-center mb-3 mb-sm-4'>
                <Link to={PROFILE_ROUTE} className="btn-4 fs-11 px-2 px-sm-3">
                    <VscArrowLeft className='fs-12' />
                </Link>
                <h1 className='inner mb-0 ms-2 ms-sm-4'>Мои адреса</h1>
            </div>
            <div className='box-2 p-5'>
                <h4 className='fw-7 text-center mb-4'>Ваш список адресов пуст</h4>
                <Link to={PROFILE_ROUTE + '/addresses/create'} className='btn-1 mx-auto'><span>Добавить адрес</span></Link>
            </div>
        </>
    }
}

export default Addresses
