import { observer } from "mobx-react-lite"
import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { FaRegTrashAlt } from "react-icons/fa"
import { VscArrowLeft } from "react-icons/vsc"
import { Link } from "react-router-dom"
import CustomModal from '../components/CustomModal'
import ProductCard from '../components/ProductCard'
import Sort from '../components/Sort'
import { Context } from "../index"
import { filterData } from './../hooks/useGlobalHook'

const Favorites = observer(() => {
    const { favorite } = useContext(Context)
    const [filter, setFilter] = useState(filterData)
    const [openModal, setOpenModal] = useState(false)

    const removeAllFavorite = () => {
        favorite.removeAllFavorite()
        setOpenModal(false)
    }

    useEffect(() => {
        document.title = "Избранное"
    }, [])

    useEffect(() => {
        if (filter) {
            favorite.sortProducts(filter.sort, favorite.products)
            localStorage.setItem('filter', JSON.stringify(filter))
        }
    }, [filter]);


    return (
        <main>
            <Container className='mb-6'>
                {
                    favorite.products && favorite.products.length > 0 ?

                        <>
                            <h1 className='inner'>Избранное</h1>

                            <div className='d-md-flex align-items-center justify-content-between'>
                                <div>
                                    <button type='button' onClick={() => setOpenModal(!openModal)} className='d-flex align-items-center'><FaRegTrashAlt size={18} /><span className='ms-2 d-md-inline'>Очистить избранные</span></button>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    <Sort value={filter.sort} onChange={(e) => setFilter({ ...filter, sort: e })} />
                                    <select className='d-none d-sm-block ms-4 fs-11 fw-7' defaultValue={'val-2'}>
                                        <option value={'val-1'}>Показывать по 12</option>
                                        <option value={'val-2'}>Показывать по 24</option>
                                        <option value={'val-3'}>Показывать все</option>
                                    </select>
                                </div>
                            </div>
                            <hr className='my-3 my-xl-4' />
                            <Row xs={2} md={3} lg={5} className="gy-4 gx-3 g-sm-5 g-md-4 g-xl-5">
                                {
                                    favorite.products.map(item => <Col><ProductCard data={item} /></Col>)
                                }
                            </Row>

                            {/* <NavPagination pagination={pagination} link={FAVORITES_ROUTE} /> */}
                        </>
                        : <>
                            <div className='d-flex align-items-center d-md-block mb-3 mb-sm-4 mb-md-0'>
                                <Link to="/" className="btn-4 fs-11 px-3 mb-md-5">
                                    <VscArrowLeft className='fs-12' />
                                    <span className='ms-2 d-none d-md-inline'>Назад на главную</span>
                                </Link>
                            </div>
                            <div className='d-flex flex-column align-items-center py-5'>
                                <h2 className='fs-20'>Нет избранных</h2>
                                <p className='text-center fs-13 mb-4 mb-sm-5'>Воспользуйтесь поиском или перейдите в каталог, чтобы заполнить её товарами</p>
                                <Link to='/catalog' className='btn-1 fs-13'>Перейти в каталог</Link>
                            </div>
                        </>
                }
            </Container>
            <CustomModal isShow={openModal} setIsShow={setOpenModal}>
                <div className='text-center'>
                    <h5 className='text-center fs-15 mb-2'>Вы уверены в очистке избранных?</h5>
                    <p className='fs-12 gray mb-4'>Все товары и настройки будут удалены</p>
                    <div className='d-flex flex-row'>
                        <button className='order-4 btn-1 fs-11 w-100 px-4 me-3 mt-2' type="button" onClick={() => removeAllFavorite()}><span>Да, продолжить</span></button>
                        <button className='order-4 btn-3 fs-11 w-100 px-4 mt-2' type="button" onClick={() => setOpenModal(!openModal)}><span>Отмена</span></button>
                    </div>
                </div>
            </CustomModal>
        </main>
    );
})

export default Favorites;
