import React from 'react';
import { Link } from 'react-router-dom';
import { ARTICLE_ROUTE } from '../utils/consts';

export default function ArticlePreview(props) {
    const { data } = props;
    if (!data) {
        return null
    }

    const imagesParse = data.images && typeof (data.images) === 'string' ? JSON.parse(data.images) : data.images
    const image = imagesParse ? imagesParse[0] : '/imgs/img3.png'

    return (
        <article className='preview'>
            <Link to={ARTICLE_ROUTE + '/' + data.id}>
                <figure>
                    <img src={'/imgs/img3.png'} alt={data.title} />
                    <figcaption>
                        <h3>{data.title}</h3>
                    </figcaption>
                </figure>
            </Link>
            <div className='mt-3 mt-md-4 d-flex justify-content-between align-items-center'>
                <h5 className='mb-0'>{data.desc}</h5>
                <Link to={ARTICLE_ROUTE + '/' + data.id} className='link td-under ms-3'>Читать</Link>
            </div>
        </article>
    );
}