import React, { useEffect } from 'react'

const NotFound = () => {
      useEffect(() => {
            document.title = "Страница не найдена"
      }, [])
      return (
            <main className='pt-4 pt-lg-5'>
                  <section id="sec-8" className="mb-8">
                        <div className="container">
                              <h1 className='inner'>404 Страница не найдена</h1>
                        </div>
                  </section>
            </main>
      );
};

export default NotFound;
